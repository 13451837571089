import axios from 'axios'

let shipmentAccess = '/shipment-access';

export const state = {
	
	listShipmentAccess: [],
	listShipmentAccessUsers: [],
	listShipmentAccessUserDetail: [],
}
export const getters = {

}
export const mutations = {
	
	setListShipmentAccess: (state, payload) => {
        state.listShipmentAccess = payload
    },
	setListShipmentAccessUsers: (state, payload) => {
        state.listShipmentAccessUsers = payload
    },
	setListShipmentAccessUserDetail: (state, payload) => {
        state.listShipmentAccessUserDetail = payload
    },
}
// function serializeQuery(params, prefix) {
// 	const query = Object.keys(params).map((key) => {
// 		const value = params[key];

// 		if (params.constructor === Array)
// 			key = `${prefix}[]`;
// 		else if (params.constructor === Object)
// 			key = (prefix ? `${prefix}[${key}]` : key);

// 		if (typeof value === 'object')
// 			return serializeQuery(value, key);
// 		else
// 			return `${key}=${encodeURIComponent(value)}`;
// 	});

// 	return [].concat.apply([], query).join('&');
// }
export const actions = {
	getlistShipmentAccess: async (context, id) => {

		let {
			data
		} = await axios.get(`${context.rootState.rootApi + shipmentAccess}`)
		context.commit('setListShipmentAccess', data)
		return data
	},

	saveShipmentAccess: async (context,payload) => {
		try {
			let {
				data
			} = await axios.post(`${context.rootState.rootApi + shipmentAccess + '/store'}`, payload)
			return data

		} catch (error) {
			console.log(error)
			context.commit('setError', error)
		}
	},
	saveUsers: async (context,payload) => {
		try {
			let {
				data
			} = await axios.post(`${context.rootState.rootApi + shipmentAccess + '/store-users'}`, payload)
			return data

		} catch (error) {
			console.log(error)
			context.commit('setError', error)
		}
	},
	saveEditDate: async (context,payload) => {
		try {
			let {
				data
			} = await axios.post(`${context.rootState.rootApi + shipmentAccess + '/edit-date'}`, payload)
			return data

		} catch (error) {
			console.log(error)
			context.commit('setError', error)
		}
	},
	saveEditDetail: async (context,payload) => {
		try {
			let {
				data
			} = await axios.post(`${context.rootState.rootApi + shipmentAccess + '/edit-detail'}`, payload)
			return data

		} catch (error) {
			console.log(error)
			context.commit('setError', error)
		}
	},
	deletesData: async (context, payload) => {
		try {
			let {
				data
			} = await axios.post(`${context.rootState.rootApi + shipmentAccess + '/delete'}`, payload)
			return data
		} catch (error) {
			context.commit('setError', error)
		}
	},

	changeStatus: async (context, id) => {
		try {
			let {
				data 
			} = await axios.get(`${context.rootState.rootApi + shipmentAccess + '/change-active/' + id}`)

			return data
		} catch (error) {
			console.log(error)
			context.commit('setError', error)
		}
	},
	getDataAccessUsers: async (context, id) => {
		try {
			let {
				data 
			} = await axios.get(`${context.rootState.rootApi + shipmentAccess + '/get-users/' + id}`)

			context.commit('setListShipmentAccessUsers', data)
		} catch (error) {
			console.log(error)
			context.commit('setError', error)
		}
	},
	getDataAccessUserDetail: async (context, id) => {
		try {
			let {
				data 
			} = await axios.get(`${context.rootState.rootApi + shipmentAccess + '/get-user-detail/' + id}`)

			context.commit('setListShipmentAccessUserDetail', data)
		} catch (error) {
			console.log(error)
			context.commit('setError', error)
		}
	},
}
