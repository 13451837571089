import axios from 'axios'

let getTruckUrl = '/fob';

export const state = {
    listFob: [],
    errors: {}
}
export const getters = {

}
export const mutations = {
    setListFob: (state, payload) => {
        state.listFob = payload
    },
    setError: (state, payload) => {
        state.errors = payload
    }
}
export const actions = {
    getListFob: async (context, payload) => {
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getTruckUrl}/get-fob`)
        context.commit('setListFob', data)
        return data
    },
}
