import axios from 'axios'

let getBuyingUrl = '/buying';

export const state = {
    listBuying: [],
    listDocCode: [],
    listTaxTypeBuying: [],
    dataSingleBuying: {},
    dataItemBuying: {},
    errors: {}
}
export const getters = {

}
export const mutations = {
    setListBuying: (state, payload) => {
        state.listBuying = payload
    },
    setListDocCode: (state, payload) => {
        state.listDocCode = payload
    },
    setListTaxTypeBuying: (state, payload) => {
        state.listTaxTypeBuying = payload
    },
    setBuying: (state, payload) => {
        state.dataSingleBuying = payload
    },
    setItemBuying: (state, payload) => {
        state.dataItemBuying = payload
    },
    setError: (state, payload) => {
        state.errors = payload
    }
}
export const actions = {
    getListBuying: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getBuyingUrl}/getBuyingList?id_job_order=${payload}`)
        context.commit('setListBuying', data)
        return data
    },
    getBuying: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getBuyingUrl}/getBuying/${payload}`)
        context.commit('setBuying', data)
        return data
    },
    getItemBuying: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getBuyingUrl}/getItemBuying/${payload}`)
        context.commit('setItemBuying', data)
        return data
    },
    getListDocCode: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getBuyingUrl}/getDocCode`)
        context.commit('setListDocCode', data)
        return data
    },
    getListTaxTypeBuying: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getBuyingUrl}/getTaxType?doc_code=${payload}`)
        context.commit('setListTaxTypeBuying', data)
        return data
    },
    getListDocCodeDP: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getBuyingUrl}/getDocCodeDP`)
        context.commit('setListDocCode', data)
        return data
    },
    getListTaxTypeBuyingDP: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getBuyingUrl}/getTaxTypeDP?doc_code=${payload}`)
        context.commit('setListTaxTypeBuying', data)
        return data
    },

    savePort: async (context, payload) => {
        let {
            data
        } = await axios.post(`${context.rootState.rootApi+getBuyingUrl}`, payload)
        return data
        // context.commit('ADD_TODO',payload)
    },
    getPort: async (context, payload) => {
        try {
            let {data} = await axios.get(`${context.rootState.rootApi+getBuyingUrl+'/'+payload}`)
            context.commit('setPort', data)
						console.log('Port', data)
        } catch (error) {
            console.log(error)
            context.commit('setError', error.message);
        }
    },
    updatePort: async (context, {payload,id}) => {
        try {
            let {
                data
            } = await axios.put(`${context.rootState.rootApi+getBuyingUrl+'/'+id}`, payload)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    deletePort: async (context, id) => {
        try {
            let {
                data
            } = await axios.delete(`${context.rootState.rootApi+getBuyingUrl+'/'+id}`)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    saveBuying: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getBuyingUrl}/store`, payload, config)
                .then(res => {
                    setTimeout(() => commit('setBuying', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    setTimeout(() => commit('setBuying', {}), 1000)
                    reject(err)
                })
        })
    },
    updateBuying: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getBuyingUrl}/update`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setBuying', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setBuying', {}), 1000)
                    reject(err)
                })
        })
    },
    updateBuyingItem: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getBuyingUrl}/updateItem`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setBuying', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setBuying', {}), 1000)
                    reject(err)
                })
        })
    },
    saveNewBuyingItem: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getBuyingUrl}/storeNewItem`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setBuying', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setBuying', {}), 1000)
                    reject(err)
                })
        })
    },
    deleteBuyingItem: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getBuyingUrl}/destroyItem/${payload}`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setBuying', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setBuying', {}), 1000)
                    reject(err)
                })
        })
    },
    deleteBuying: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getBuyingUrl}/destroyBuying/${payload}`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setBuying', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setBuying', {}), 1000)
                    reject(err)
                })
        })
    },
    submitAOL: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getBuyingUrl}/submitAOL/${payload}`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setBuying', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setBuying', {}), 1000)
                    reject(err)
                })
        })
    },
}
