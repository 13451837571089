import axios from 'axios'

let receiveableItem = '/receiveable-item';

export const state = {

	RIDetail: [],
	listBuying: []
}
export const getters = {

}
export const mutations = {
	// setListPurchaseNumber: (state, payload) => {
    //     state.listPurchaseNumber = payload
    // },
	setListRiDetails: (state, payload) => {
        state.RIDetail = payload
    },
	setListBuying: (state, payload) => {
		state.listBuying = payload
	},

}
function serializeQuery(params, prefix) {
	const query = Object.keys(params).map((key) => {
		const value = params[key];

		if (params.constructor === Array)
			key = `${prefix}[]`;
		else if (params.constructor === Object)
			key = (prefix ? `${prefix}[${key}]` : key);

		if (typeof value === 'object')
			return serializeQuery(value, key);
		else
			return `${key}=${encodeURIComponent(value)}`;
	});

	return [].concat.apply([], query).join('&');
}
export const actions = {
	// getlistDocument: async (context, id) => {

	//     let {
	//         data
	//     } = await axios.get(`${context.rootState.rootApi + distribution + '/document-set-general/' + id}`)
	//     context.commit('getDocument', data)
	//     return data
	// },
	// getTypeOptions: async (context, payload) => {
	//     try {
	//         let {
	//             data
	//         } = await axios.get(`${context.rootState.rootApi + distribution + '/get-type-options/' + payload}`)
	//         // context.commit('settypeOptions', data, { root: true })
	//         return data
	//     } catch (error) {
	//         console.log(error)
	//         context.commit('setError', error)
	//     }
	// },
	getlistRI: async (context, id) => {

		let {
			data
		} = await axios.get(`${context.rootState.rootApi + receiveableItem + '/edit/' + id}`)
		context.commit('getRI', data)
		return data
	},
	// getlistBalanceRI: async (context, id) => {

	// 	let {
	// 		data
	// 	} = await axios.get(`${context.rootState.rootApi + receiveableItem + '/get-item-balance/' + id}`)
	// 	context.commit('getRI', data)
	// 	return data
	// },
	saveReceiveableItem: async (context, payload) => {
		try {
			let {
				data
			} = await axios.post(`${context.rootState.rootApi + receiveableItem + '/store'}`, payload)
			return data

		} catch (error) {
			console.log(error)
			context.commit('setError', error)
		}
		// context.commit('ADD_TODO',payload)
	},
	editReceiveableItem: async (context, payload) => {
		try {
			let {
				data
			} = await axios.post(`${context.rootState.rootApi + receiveableItem + '/update'}`, payload)
			return data

		} catch (error) {
			console.log(error)
			context.commit('setError', error)
		}
		// context.commit('ADD_TODO',payload)
	},
	getlistRiDetails: async (context, payload) => {
		let {
			data
		} = await axios.post(`${context.rootState.rootApi + receiveableItem + '/detail'}`, payload)
		context.commit('getRiDetail', data)
		return data
	},
	deleteReceiveableItem: async (context, id) => {
		try {
			let {
				data
			} = await axios.get(`${context.rootState.rootApi + receiveableItem + '/delete/' + id}`)
			return data
		} catch (error) {
			context.commit('setError', error)
		}
	},
	hardDeleteReceiveableItem: async (context, id) => {
		try {
			let {
				data
			} = await axios.get(`${context.rootState.rootApi + receiveableItem + '/hard-delete/' + id}`)
			return data
		} catch (error) {
			context.commit('setError', error)
		}
	},
	submitAOL: async (context, payload) => {
		try {
			let {
				data
			} = await axios.post(`${context.rootState.rootApi + receiveableItem + '/submit-aol'}`, payload)
			return data
		} catch (error) {
			console.log(error)
			context.commit('setError', error)
		}
	},
	getlistBalanceRI: async (context, id) => {

		let {
			data
		} = await axios.get(`${context.rootState.rootApi + receiveableItem + '/get-item-balance/' + id}`)
		context.commit('getRI', data)
		return data
	},
	getlistBalanceRIMulti: async (context, idMulti) => {
		let result = serializeQuery(idMulti, 'id');

		let {
			data
		} = await axios.get(`${context.rootState.rootApi + receiveableItem + '/get-item-balance-multi?' + result}`)
		context.commit('getRI', data)
		return data
	},
	getListBuying: async (context, payload) => {
		// let config = {
		//     headers: {
		//         'company' : 2
		//     }
		// }
		let {
			data
		} = await axios.get(`${context.rootState.rootApi + receiveableItem}/get-applied-transaction?id_receiveable_item=${payload.idJoborder}&search=${payload.search}&page=${payload.page}&per_page=${payload.per_page}`)
		context.commit('setListBuying', data)
		return data
	},

	// deleteJobOrderDistribution: async (context, id) => {
	//     try {
	//         let {
	//             data
	//         } = await axios.get(`${context.rootState.rootApi + distribution + '/delete/' + id}`)
	//         return data
	//     } catch (error) {
	//         console.log(error)
	//         context.commit('setError', error)
	//     }
	// },

	// submitAOL: async (context, id) => {
	//     try {
	//         let {
	//             data
	//         } = await axios.get(`${context.rootState.rootApi + distribution + '/submit-aol/' + id}`)
	//         return data
	//     } catch (error) {
	//         console.log(error)
	//         context.commit('setError', error)
	//     }
	// },

	// saveDistributionBackupTruck: async (context, payload) => {
	//     let {
	//         data
	//     } = await axios.post(`${context.rootState.rootApi + distribution + '/backup-truck'}`, payload)
	//     return data
	//     // context.commit('ADD_TODO',payload)
	// },


}
