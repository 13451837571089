import axios from 'axios'

let getCustomClearanceUrl = '/api/custom-clearance';

export const state = {
    listCustomClearance: [],
    dataCustomClearance: {},
    errors: {}
}
export const getters = {
    
}
export const mutations = {
    setListCustomClearance: (state, payload) => {
        state.listCustomClearance = payload
    },
    setCustomClearance: (state, payload) => {
        state.dataCustomClearance = payload
    },
    setError: (state, payload) => {
        state.errors = payload
    }
}
export const actions = {
    getListCustomClearance: async (context, payload) => {
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getCustomClearanceUrl}`)
        context.commit('setListCustomClearance', data.data)
        console.log('CustomClearance', data)
        return data
    },
    saveCustomClearance: async (context, payload) => {
        let {
            data
        } = await axios.post(`${context.rootState.rootApi+getCustomClearanceUrl}`, payload)
        return data
        // context.commit('ADD_TODO',payload)
    },
    getCustomClearance: async (context, payload) => {
        try {
            let {data} = await axios.get(`${context.rootState.rootApi+getCustomClearanceUrl+'/'+payload}`)
            context.commit('setCustomClearance', data.data)
        } catch (error) {
            console.log(error)
            context.commit('setError', error.message);
        }
    },
    updateCustomClearance: async (context, {payload,id}) => {
        try {
            let {
                data
            } = await axios.put(`${context.rootState.rootApi+getCustomClearanceUrl+'/'+id}`, payload)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    deleteCustomClearance: async (context, id) => {
        try {
            let {
                data
            } = await axios.delete(`${context.rootState.rootApi+getCustomClearanceUrl+'/'+id}`)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    }
}