import axios from 'axios'

let getBranchUrl = '/branch';

export const state = {
    listBranch: [],
    dataBranch: {},
    errors: {}
}
export const getters = {

}
export const mutations = {
    setListBranch: (state, payload) => {
        state.listBranch = payload
    },
    setBranch: (state, payload) => {
        state.dataBranch = payload
    },
    setError: (state, payload) => {
        state.errors = payload
    }
}
export const actions = {
    getListBranch: async (context, payload) => {
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getBranchUrl}`)
        context.commit('setListBranch', data)
        // console.log('Branch', data)
        return data
    },
    saveBranch: async (context, payload) => {
        let {
            data
        } = await axios.post(`${context.rootState.rootApi+getBranchUrl}`, payload)
        return data
        // context.commit('ADD_TODO',payload)
    },
    getBranch: async (context, payload) => {
        try {
            let {data} = await axios.get(`${context.rootState.rootApi+getBranchUrl+'/'+payload}`)
            context.commit('setBranch', data)
        } catch (error) {
            console.log(error)
            context.commit('setError', error.message);
        }
    },
    updateBranch: async (context, {payload,id}) => {
        try {
            let {
                data
            } = await axios.put(`${context.rootState.rootApi+getBranchUrl+'/'+id}`, payload)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    deleteBranch: async (context, id) => {
        try {
            let {
                data
            } = await axios.delete(`${context.rootState.rootApi+getBranchUrl+'/'+id}`)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    }
}
