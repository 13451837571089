<script>
export default {
  components: {},
  created: () => {
    document.body.classList.add('authentication-bg')
    //  document.body.classList.add('authentication-bg-pattern')
  },
}
</script>

<template>
  <div>
    <!-- <div class="account-pages"> -->
      <!-- <div class="container"> -->
      <slot />
      <!-- </div> -->
    <!-- </div> -->
    <!-- <div class="bg-info">
      <footer class="footer footer-alt bg-info">
    2016 - 2019 © JALPEDIA
    <a href class="text-muted">Coderthemes</a>
    </footer>
     </div> -->
  </div>
</template>
