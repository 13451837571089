import axios from 'axios'

let getCurrencyUrl = '/currency';
let getDefaultCurrencyUrl = '/def/curr';
let getKurs = '/currency/kurs';
let getKursWithDate = '/currency/kursWithDate';

export const state = {
    listCurrency: [],
    dataCurrency: {},
    dataSingleCurrency: {},
    dataDefaultCurrency: {},
    kursData: {},
    errors: {}
}
export const getters = {

}
export const mutations = {
    setListCurrency: (state, payload) => {
        state.listCurrency = payload
    },
    setCurrency: (state, payload) => {
        state.dataCurrency = payload
    },
    setSingleCurrency: (state, payload) => {
        state.dataSingleCurrency = payload
    },
    setKurs: (state, payload) => {
        state.kursData = payload
    },
    setDefaultCurrency: (state, payload) => {
        state.dataDefaultCurrency = payload
    },
    setError: (state, payload) => {
        state.errors = payload
    }
}
export const actions = {
    getListCurrency: async (context, payload) => {
        let config = {
            headers: {
                'company' : 2
            }
        }
        let dataDefault ={}
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getCurrencyUrl}`, config)
				

                // console.log(dataDefault, 'data default curr')
                context.commit('setCurrency', dataDefault)
        context.commit('setListCurrency', data)
        // console.log('Currency', data)
        return data
    },
    saveCurrency: async (context, payload) => {
        let {
            data
        } = await axios.post(`${context.rootState.rootApi+getCurrencyUrl}`, payload)
        return data
        // context.commit('ADD_TODO',payload)
    },
    getCurrency: async (context, payload) => {
        try {
            let {data} = await axios.get(`${context.rootState.rootApi+getCurrencyUrl+'/'+payload}`)
            context.commit('setCurrency', data)
        } catch (error) {
            console.log(error)
            context.commit('setError', error.message);
        }
    },
    getSingleCurrency: async (context, id) => {
        try {
            let {data} = await axios.get(`${context.rootState.rootApi+getCurrencyUrl+'/'+id}`)
            context.commit('setSingleCurrency', data)
        } catch (error) {
            console.log(error)
            context.commit('setError', error.message);
        }
    },
    getDefaultCurrency: async (context, payload) => {
        try {
            let {data} = await axios.get(`${context.rootState.rootApi+getDefaultCurrencyUrl}`)
            context.commit('setDefaultCurrency', data)
        } catch (error) {
            console.log(error)
            context.commit('setError', error.message);
        }
    },
    updateCurrency: async (context, {payload,id}) => {
        try {
            let {
                data
            } = await axios.put(`${context.rootState.rootApi+getCurrencyUrl+'/'+id}`, payload)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    deleteCurrency: async (context, id) => {
        try {
            let {
                data
            } = await axios.delete(`${context.rootState.rootApi+getCurrencyUrl+'/'+id}`)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    getKurs: async (context, id) => {
        try {
            let {data} = await axios.get(`${context.rootState.rootApi+getKurs+'/'+id}`)
            context.commit('setKurs', data)
        } catch (error) {
            console.log(error)
            context.commit('setError', error.message);
        }
    },
    getKursWithDate: async (context, payload) => {
        try {
            let {data} = await axios.get(`${context.rootState.rootApi+getKursWithDate}`, {
                params: payload
            })
            context.commit('setKurs', data)
        } catch (error) {
            console.log(error)
            context.commit('setError', error.message);
        }
    },
}
