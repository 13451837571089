import axios from 'axios'

let getLocalChargeUrl = '/api/local-charge';

export const state = {
    listLocalCharge: [],
    dataLocalCharge: {},
    errors: {}
}
export const getters = {
    
}
export const mutations = {
    setListLocalCharge: (state, payload) => {
        state.listLocalCharge = payload
    },
    setLocalCharge: (state, payload) => {
        state.dataLocalCharge = payload
    },
    setError: (state, payload) => {
        state.errors = payload
    }
}
export const actions = {
    getListLocalCharge: async (context, payload) => {
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getLocalChargeUrl}`)
        context.commit('setListLocalCharge', data.data)
        console.log('LocalCharge', data)
        return data
    },
    saveLocalCharge: async (context, payload) => {
        let {
            data
        } = await axios.post(`${context.rootState.rootApi+getLocalChargeUrl}`, payload)
        return data
        // context.commit('ADD_TODO',payload)
    },
    getLocalCharge: async (context, payload) => {
        try {
            let {data} = await axios.get(`${context.rootState.rootApi+getLocalChargeUrl+'/'+payload}`)
            context.commit('setLocalCharge', data.data)
        } catch (error) {
            console.log(error)
            context.commit('setError', error.message);
        }
    },
    updateLocalCharge: async (context, {payload,id}) => {
        try {
            let {
                data
            } = await axios.put(`${context.rootState.rootApi+getLocalChargeUrl+'/'+id}`, payload)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    deleteLocalCharge: async (context, id) => {
        try {
            let {
                data
            } = await axios.delete(`${context.rootState.rootApi+getLocalChargeUrl+'/'+id}`)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    }
}