import axios from 'axios'

let getJobfileUrl = '/jobfile';

export const state = {
	chart: [],
	dataUser: {},
	listJobfile: [],
	listJobfileByVessel: [],
	listJobfileName: [],
	listHblAll: [],
	listMblAll: [],
	listMawbAll: [],
	listHawbAll: [],
	listBlAll: [],
	errors: {}
}
export const getters = {

}
export const mutations = {
	setChart: (state, payload) => {
		state.chart = payload
	},
	setUser: (state, payload) => {
		state.dataUser = payload
	},
	setListJobfile: (state, payload) => {
		state.listJobfile = payload
	},
	setListJobfileByVessel: (state, payload) => {
		state.listJobfileByVessel = payload
	},
	setListJobfileName: (state, payload) => {
		state.listJobfileName = payload
	},
	setListHblAll: (state, payload) => {
		state.listHblAll = payload
	},
	setListMblAll: (state, payload) => {
		state.listMblAll = payload
	},
	setListMawbAll: (state, payload) => {
		state.listMawbAll = payload
	},
	setListHawbAll: (state, payload) => {
		state.listHawbAll = payload
	},
	setListBlAll: (state, payload) => {
		state.listBlAll = payload
	},
	setError: (state, payload) => {
		state.errors = payload
	}
}
export const actions = {
	getChart: async (context, payload) => {
		// let config = {
		//     headers: {
		//         'company' : 2
		//     }
		// }
		var url = '/getChart'
		var status = false

		if (JSON.parse(localStorage.getItem('userPermissions')).length != null) {
			for (let i = 0; i < JSON.parse(localStorage.getItem('userPermissions')).length; i++) {

				if (JSON.parse(localStorage.getItem('userPermissions'))[i] === 'CRM_ADMIN_ENABLE') {
					url = '/getChart?admin=true';
				}

			}
		}


		console.log(status, 'status')
		console.log(url, 'url')
		let {
			data
		} = await axios.get(`${context.rootState.rootApi + getJobfileUrl + url}`)
		context.commit('setChart', data)
		console.log('ChartPie', data)
		return data
	},
	getListJobfile: async (context, payload) => {
		// let config = {
		//     headers: {
		//         'company' : 2
		//     }
		// }
		let {
			data
		} = await axios.get(`${context.rootState.rootApi + getJobfileUrl}/getAllJobfile`)
		context.commit('setListJobfile', data)
		return data
	},
	getListJobfileName: async (context, payload) => {
		let {
			data
		} = await axios.get(`${context.rootState.rootApi + getJobfileUrl}/getAllJobfile`)

		const result = data.map((item) => {
			return {
				id: item.id,
				name: item.jobfile_no
			}
		})
		context.commit('setListJobfileName', result)
		return result;
	},
	getListJobfileByVessel: async (context, payload) => {
		let {
			data
		} = await axios.post(`${context.rootState.rootApi + getJobfileUrl}/get-list-jobfile-by-vessel`, payload)

		context.commit('setListJobfileByVessel', data.data)
		return data.data;
	},
	getListHblAll: async (context, payload) => {
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getJobfileUrl}/get-hbl-all`)
        context.commit('setListHblAll', data)
        return data
    },
	getListMblAll: async (context, payload) => {
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getJobfileUrl}/get-mbl-all`)
        context.commit('setListMblAll', data)
        return data
    },
	getListHawbAll: async (context, payload) => {
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getJobfileUrl}/get-hawb-all`)
        context.commit('setListHawbAll', data)
        return data
    },
	getListMawbAll: async (context, payload) => {
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getJobfileUrl}/get-mawb-all`)
        context.commit('setListMawbAll', data)
        return data
    },
	getListBlAll: async (context, payload) => {
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getJobfileUrl}/get-bl-all`)
        context.commit('setListBlAll', data)
        return data
    },
	// saveUser: async (context, payload) => {
	//     let {
	//         data
	//     } = await axios.post(`${context.rootState.rootApi+getUserUrl}`, payload)
	//     return data
	//     // context.commit('ADD_TODO',payload)
	// },
	// getUsername: async (context, payload) => {
	//     try {
	//         console.log('username', payload)
	//         let {data} = await axios.get(`${context.rootState.rootApi+getUserUrl+'/'+payload}`)
	//         context.commit('setUser', data.result[0])
	//         console.log('data user', data.result[0])
	//     } catch (error) {
	//         console.log(error)
	//         context.commit('setError', error.message);
	//     }
	// },
	// updateUser: async (context, {payload,id}) => {
	//     try {
	//         let {
	//             data
	//         } = await axios.put(`${context.rootState.rootApi+getUserUrl+'/'+id}`, payload)
	//         return data
	//     } catch (error) {
	//         console.log(error)
	//         context.commit('setError', error)
	//     }
	// },
	// deleteUser: async (context, id) => {
	//     try {
	//         let {
	//             data
	//         } = await axios.delete(`${context.rootState.rootApi+getUserUrl+'/'+id}`)
	//         return data
	//     } catch (error) {
	//         console.log(error)
	//         context.commit('setError', error)
	//     }
	// }
}
