import axios from 'axios'

let getCustomerUrl = '/api/customer';

export const state = {
    listCustomer: [],
    dataCustomer: {},
    errors: {}
}
export const getters = {
    
}
export const mutations = {
    setListCustomer: (state, payload) => {
        state.listCustomer = payload
    },
    setCustomer: (state, payload) => {
        state.dataCustomer = payload
    },
    setError: (state, payload) => {
        state.errors = payload
    }
}
export const actions = {
    getListCustomer: async (context, payload) => {
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getCustomerUrl}`)
        console.log("testtt", data)
        context.commit('setListCustomer', data)
        return data
    },
    saveCustomer: async (context, payload) => {
        let {
            data
        } = await axios.post(`${context.rootState.rootApi+getCustomerUrl}`, payload)
        return data
        // context.commit('ADD_TODO',payload)
    },
    getCustomer: async (context, payload) => {
        try {
            let {data} = await axios.get(`${context.rootState.rootApi+getCustomerUrl+'/'+payload}`)
            context.commit('setCustomer', data.data)
        } catch (error) {
            console.log(error)
            context.commit('setError', error.message);
        }
    },
    updateCustomer: async (context, {payload,id}) => {
        try {
            let {
                data
            } = await axios.put(`${context.rootState.rootApi+getCustomerUrl+'/'+id}`, payload)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    deleteCustomer: async (context, id) => {
        try {
            let {
                data
            } = await axios.delete(`${context.rootState.rootApi+getCustomerUrl+'/'+id}`)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    }
}