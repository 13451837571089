import Vue from 'vue'
import VueRouter from 'vue-router'
// https://github.com/declandewet/vue-meta
import VueMeta from 'vue-meta'
// Adds a loading bar at the top during page loads.
import NProgress from 'nprogress/nprogress'
import store from '@state/store'
import routes from './routes'
import Axios from 'axios'
Vue.prototype.$http = Axios;
Vue.use(VueRouter)
Vue.use(VueMeta, {
	// The component option name that vue-meta looks for meta info on.
	keyName: 'page',
})


const router = new VueRouter({
	routes,
	// Use the HTML5 history API (i.e. normal-looking routes)
	// instead of routes with hashes (e.g. example.com/#/about).
	// This may require some server configuration in production:
	// https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
	mode: 'history',
	// Simulate native-like scroll behavior when navigating to a new
	// route and using back/forward buttons.
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return {
				x: 0,
				y: 0
			}
		}
	},
})

const userPermissions = JSON.parse(localStorage.getItem('userPermissions'))


// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next, axios) => {


	if (userPermissions != null) {

		if (routeTo.meta.permissions && routeTo.meta.permissions.length > 0) {
			let isAllowed = userPermissions.some(p => routeTo.meta.permissions.includes(p))

			if (!isAllowed) return next({
				name: '404'
			})
		}
	}

	// If this isn't an initial page load...
	if (routeFrom.name !== null) {
		// Start the route progress bar.
		NProgress.start()
	}

	// Check if auth is required on this route
	// (including nested routes).
	const authRequired = routeTo.matched.some((route) => route.meta.authRequired)

	// If auth isn't required for the route, just continue.
	if (!authRequired) return next()

	// If auth is required and the user is logged in...
	// if (store.getters['auth/loggedIn']) {
	// Validate the local user token...
	return store.dispatch('auth/validate').then((validUser) => {
		// Then continue if the token still represents a valid user,
		// otherwise redirect to login.
		validUser ? next() : redirectToLogin()
		// next();
	})
	// }

	// If auth is required and the user is NOT currently logged in,
	// redirect to login.
	// redirectToLogin()

	function redirectToLogin() {
		// Pass the original route to the login component
		window.location.href = process.env.VUE_APP_CI_URL + '/admin'
		// next({
		// 	name: 'login',
		// 	query: {
		// 		redirectFrom: routeTo.fullPath
		// 	}
		// })



		// console.log({
		// 	query: routeTo.fullPath
		// })
		// console.log(routeTo.query._tkn, 'query quptaion')
		// if (routeTo.query._tkn) {
		// 	const token= {
		// 		access_token : routeTo.query._tkn
		// 	}
		// 	localStorage.setItem('auth.currentToken',JSON.stringify(token))
		// 	next({
		// 		name:'Quotation List'
		// 	})
		// 	console.log('masuk localstorage')
		// }else{
		// 	next({
		// 		name: 'login',
		// 		query: {
		// 			redirectFrom: routeTo.fullPath
		// 		}
		// 	})
		// }
	}


})

router.beforeResolve(async (routeTo, routeFrom, next) => {
	// Create a `beforeResolve` hook, which fires whenever
	// `beforeRouteEnter` and `beforeRouteUpdate` would. This
	// allows us to ensure data is fetched even when params change,
	// but the resolved route does not. We put it in `meta` to
	// indicate that it's a hook we created, rather than part of
	// Vue Router (yet?).
	try {
		// For each matched route...
		for (const route of routeTo.matched) {
			await new Promise((resolve, reject) => {
				// If a `beforeResolve` hook is defined, call it with
				// the same arguments as the `beforeEnter` hook.
				if (route.meta && route.meta.beforeResolve) {
					route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
						// If the user chose to redirect...
						if (args.length) {
							// If redirecting to the same route we're coming from...
							if (routeFrom.name === args[0].name) {
								// Complete the animation of the route progress bar.
								NProgress.done()
							}
							// Complete the redirect.
							next(...args)
							reject(new Error('Redirected'))
						} else {
							// console.log('resolve in meta')
							resolve()
						}
					})
				} else {
					// console.log('resolve')
					// Otherwise, continue resolving the route.
					resolve()
				}
			})
		}
		// If a `beforeResolve` hook chose to redirect, just return.
	} catch (error) {
		// console.log(error)
		return
	}
	// console.log('berhasil next')
	// If we reach this point, continue resolving the route.
	next()
})



// When each route is finished evaluating...
router.afterEach((routeTo, routeFrom) => {
	// Complete the animation of the route progress bar.
	NProgress.done()
})

export default router
