import axios from 'axios'

let getItemUrl = '/item';
let getItemUrlPpn = '/item_ppn';
let getItemUrlTax = '/item_tax';
let getItemUrlCategory = '/item_category';
let getItemUrlNonInventory = '/item_non_inventory';
let getItemUrlDiscount = '/item_discount';
let getItemSparepartInUrl = '/item-sparepartin';

export const state = {
	listItem: [],
	listItemTax: [],
	listItems: [],
	listItemName: [],
	listItemBank: [],
	listItemSparepartIn: [],
	listItemNonInventory: [],
	ItemPpn: [],
	listItemDiscount: [],
	dataItem: {},
	errors: {}
}
export const getters = {

}
export const mutations = {
	setListItem: (state, payload) => {
		state.listItem = payload
	},
	setListItems: (state, payload) => {
		state.listItems = payload
	},
	setListItemTax: (state, payload) => {
		state.listItemTax = payload
	},
	setListItemName: (state, payload) => {
		state.listItemName = payload
	},
	setListItemBank: (state, payload) => {
		state.listItemBank = payload
	},
	setListItemNonInventory: (state, payload) => {
		state.listItemNonInventory = payload
	},
	setListItemSparepartIn: (state, payload) => {
		state.listItemSparepartIn = payload
	},
	setItemPpn: (state, payload) => {
		state.ItemPpn = payload
	},
	setItem: (state, payload) => {
		state.dataItem = payload
	},
	setListItemDiscount: (state, payload) => {
		state.listItemDiscount = payload
	},
	setError: (state, payload) => {
		state.errors = payload
	}
}
export const actions = {
	getListItem: async (context, payload) => {
		// let config = {
		//     headers: {
		//         'company' : 2
		//     }
		// }
		let {
			data
		} = await axios.get(`${context.rootState.rootApi + getItemUrl}`)
		context.commit('setListItem', data)
		context.commit('setListItems', data)
		return data
	},
	getListItemName: async (context, payload) => {
		let {
			data
		} = await axios.get(`${context.rootState.rootApi + getItemUrlPpn + '?ppn=' + payload.id + '&item_category=1'}`)

		const maps = data.map((item) => {
			return {
				id: item.id,
				name: item.item_name
			}
		})

		context.commit('setListItemName', maps)
		return maps
	},
	getListItemSparepartIn: async (context, payload) => {
		// let config = {
		//     headers: {
		//         'company' : 2
		//     }
		// }
		let {
			data
		} = await axios.get(`${context.rootState.rootApi + getItemSparepartInUrl}`)
		context.commit('setListItemSparepartIn', data)
		return data
	},
	getItemPpn: async (context, payload) => {
		// let config = {
		//     headers: {
		//         'company' : 2
		//     }
		// }
		let {
			data
		} = await axios.get(`${context.rootState.rootApi + getItemUrl + '?ppn=' + payload}`)
		context.commit('setItemPpn', data)
		console.log('Item', data)
		return data
	},
	getItemByPpn: async (context, payload) => {
		// let config = {
		//     headers: {
		//         'company' : 2
		//     }
		// }
		let {
			data
		} = await axios.get(`${context.rootState.rootApi + getItemUrlPpn + '?ppn=' + payload.ppn}&item_category=${payload.itemCategory}`)
		context.commit('setListItem', data)
		context.commit('setListItemTax', data)
		return data
	},
	getItemByTax: async (context, payload) => {
		// let config = {
		//     headers: {
		//         'company' : 2
		//     }
		// }
		let {
			data
		} = await axios.get(`${context.rootState.rootApi + getItemUrlTax + '?ppn=' + payload.ppn}&pph=${payload.pph}&item_category=${payload.itemCategory}`)
		context.commit('setListItem', data)
		context.commit('setListItemTax', data)
		return data
	},
	getItemByTaxMapping: async (context, payload) => {
		// let config = {
		//     headers: {
		//         'company' : 2
		//     }
		// }
		let {
			data
		} = await axios.get(`${context.rootState.rootApi + getItemUrlTax + '?ppn=' + payload.ppn}&pph=${payload.pph}&item_category=${payload.itemCategory}`)

		const maps = data.map((item) => {
			return {
				id: item.id,
				name: item.item_name
			}
		})

		context.commit('setListItemName', maps)
		return maps
	},
	getItemByCategory: async (context, payload) => {
		// let config = {
		//     headers: {
		//         'company' : 2
		//     }
		// }
		let {
			data
		} = await axios.get(`${context.rootState.rootApi + getItemUrlCategory}?item_category=${payload.itemCategory}`)
		context.commit('setListItem', data)
		return data
	},
	getItemByCategoryBank: async (context, payload) => {
		// let config = {
		//     headers: {
		//         'company' : 2
		//     }
		// }
		let {
			data
		} = await axios.get(`${context.rootState.rootApi + getItemUrlCategory}?item_category=${payload.itemCategory}`)
		context.commit('setListItemBank', data)
		return data
	},
	getItemNonInventory: async (context, payload) => {
		// let config = {
		//     headers: {
		//         'company' : 2
		//     }
		// }
		let {
			data
		} = await axios.get(`${context.rootState.rootApi + getItemUrlNonInventory}`)
		context.commit('setListItem', data)
		return data
	},
	getItemNonInventoryDiffVar: async (context, payload) => {
		// let config = {
		//     headers: {
		//         'company' : 2
		//     }
		// }
		let {
			data
		} = await axios.get(`${context.rootState.rootApi + getItemUrlNonInventory}`)
		context.commit('setListItemNonInventory', data)
		return data
	},
	getItemForDiscount: async (context, payload) => {
		let {
			data
		} = await axios.get(`${context.rootState.rootApi + getItemUrlDiscount}`)
		context.commit('setListItemDiscount', data)
		return data
	},
	saveItem: async (context, payload) => {
		let {
			data
		} = await axios.post(`${context.rootState.rootApi + getItemUrl}`, payload)
		return data
		// context.commit('ADD_TODO',payload)
	},
	getItem: async (context, payload) => {
		try {
			let { data } = await axios.get(`${context.rootState.rootApi + getItemUrl + '/' + payload}`)
			context.commit('setItem', data)
		} catch (error) {
			console.log(error)
			context.commit('setError', error.message);
		}
	},
	updateItem: async (context, { payload, id }) => {
		try {
			let {
				data
			} = await axios.put(`${context.rootState.rootApi + getItemUrl + '/' + id}`, payload)
			return data
		} catch (error) {
			console.log(error)
			context.commit('setError', error)
		}
	},
	deleteItem: async (context, id) => {
		try {
			let {
				data
			} = await axios.delete(`${context.rootState.rootApi + getItemUrl + '/' + id}`)
			return data
		} catch (error) {
			console.log(error)
			context.commit('setError', error)
		}
	}
}
