import axios from 'axios'

let getJournalUrl = '/journal';

export const state = {
    listJournal: [],
    dataSingleJournal: {},
    dataItemJournal: {},
    errors: {}
}
export const getters = {

}
export const mutations = {
    setListJournal: (state, payload) => {
        state.listJournal = payload
    },
    setJournal: (state, payload) => {
        state.dataSingleJournal = payload
    },
    setItemJournal: (state, payload) => {
        state.dataItemJournal = payload
    },
    setError: (state, payload) => {
        state.errors = payload
    }
}
export const actions = {
    getListJournal: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getJournalUrl}/getJournalList?id_job_order=${payload}`)
        context.commit('setListJournal', data)
        return data
    },
    getJournal: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getJournalUrl}/getJournal/${payload}`)
        context.commit('setJournal', data)
        return data
    },
    getItemJournal: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getJournalUrl}/getItemJournal/${payload}`)
        context.commit('setItemJournal', data)
        return data
    },
    getListDocCode: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getJournalUrl}/getDocCode`)
        context.commit('setListDocCode', data)
        return data
    },
    getListTaxTypeJournal: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getJournalUrl}/getTaxType?doc_code=${payload}`)
        context.commit('setListTaxTypeJournal', data)
        return data
    },

    savePort: async (context, payload) => {
        let {
            data
        } = await axios.post(`${context.rootState.rootApi+getJournalUrl}`, payload)
        return data
        // context.commit('ADD_TODO',payload)
    },
    getPort: async (context, payload) => {
        try {
            let {data} = await axios.get(`${context.rootState.rootApi+getJournalUrl+'/'+payload}`)
            context.commit('setPort', data)
						console.log('Port', data)
        } catch (error) {
            console.log(error)
            context.commit('setError', error.message);
        }
    },
    updatePort: async (context, {payload,id}) => {
        try {
            let {
                data
            } = await axios.put(`${context.rootState.rootApi+getJournalUrl+'/'+id}`, payload)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    deletePort: async (context, id) => {
        try {
            let {
                data
            } = await axios.delete(`${context.rootState.rootApi+getJournalUrl+'/'+id}`)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    saveJournal: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getJournalUrl}/store`, payload, config)
                .then(res => {
                    setTimeout(() => commit('setJournal', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    setTimeout(() => commit('setJournal', {}), 1000)
                    reject(err)
                })
        })
    },
    updateJournal: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getJournalUrl}/update`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setJournal', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setJournal', {}), 1000)
                    reject(err)
                })
        })
    },
    updateJournalItem: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getJournalUrl}/updateItem`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setJournal', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setJournal', {}), 1000)
                    reject(err)
                })
        })
    },
    saveNewJournalItem: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getJournalUrl}/storeNewItem`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setJournal', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setJournal', {}), 1000)
                    reject(err)
                })
        })
    },
    deleteJournalItem: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getJournalUrl}/destroyItem/${payload}`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setJournal', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setJournal', {}), 1000)
                    reject(err)
                })
        })
    },
    deleteJournal: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getJournalUrl}/destroyJournal/${payload}`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setJournal', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setJournal', {}), 1000)
                    reject(err)
                })
        })
    },
    submitAOL: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getJournalUrl}/submitAOL/${payload}`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setJournal', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setJournal', {}), 1000)
                    reject(err)
                })
        })
    },
}
