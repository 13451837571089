<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6"> © 2011 - {{ currentYear }} rimbahouse.com </div>
        <div class="col-md-6">
          <div class="text-md-right footer-links d-none d-sm-block"> </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(), // 2022
    }
  },
}
</script>
