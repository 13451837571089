import axios from 'axios'

let getBuyingUrl = '/multiBuyingDownPayment';

export const state = {
    listBuyingDownPayment: [],
    dataSingleBuyingDownPayment: {},
    dataSingleBuyingPurchasePayment: {},
    dataSingleBuyingPurchasePaymentTax: {},
    listBuying: [],
    listDpByVendor: [],
    dataSingleBuying: {},
    dataItemBuying: {},
    dataBalanceDP: {},
    errors: {}
}
export const getters = {

}
export const mutations = {
    setListBuyingDownPayment: (state, payload) => {
        state.listBuyingDownPayment = payload
    },
    setBuyingDownPayment: (state, payload) => {
        state.dataSingleBuyingDownPayment = payload
    },
    setBuyingPurchasePayment: (state, payload) => {
        state.dataSingleBuyingPurchasePayment = payload
    },
    setBuyingPurchasePaymentTax: (state, payload) => {
        state.dataSingleBuyingPurchasePaymentTax = payload
    },
    setListBuying: (state, payload) => {
        state.listBuying = payload
    },
    setListDpByVendor: (state, payload) => {
        state.listDpByVendor = payload
    },
    setBuying: (state, payload) => {
        state.dataSingleBuying = payload
    },
    setItemBuying: (state, payload) => {
        state.dataItemBuying = payload
    },
    setBalanceDP: (state, payload) => {
        state.dataBalanceDP = payload
    },
    setError: (state, payload) => {
        state.errors = payload
    }
}
export const actions = {
    getListBuying: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getBuyingUrl}/getBuyingList?id_down_payment=${payload.idJoborder}&search=${payload.search}&page=${payload.page}&per_page=${payload.per_page}`)
        context.commit('setListBuying', data)
        return data
    },
    getListBuyingDownPayment: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getBuyingUrl}/getBuyingDownPaymentList?id_job_order=${payload}`)
        context.commit('setListBuyingDownPayment', data)
        return data
    },
    getListBuyingDownPaymentByBuying: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getBuyingUrl}/getBuyingDownPaymentListByBuying?id_job_order_selling=${payload}`)
        context.commit('setListBuyingDownPayment', data)
        return data
    },
    getItemBuying: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        // let {
        //     data
        // } = await axios.get(`${context.rootState.rootApi+getBuyingUrl}/getItemBuying/${payload}`)
        context.commit('setItemBuying', payload)
        return payload
    },
    getBalanceDP: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getBuyingUrl}/getBalanceDP/${payload}`)
        context.commit('setBalanceDP', data)
        return data
    },
    getBuying: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getBuyingUrl}/getBuying/${payload}`)
        context.commit('setBuying', data)
        return data
    },
    getBuyingDownPayment: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getBuyingUrl}/getBuyingDownPayment/${payload}`)
        context.commit('setBuyingDownPayment', data)
        return data
    },
    getBuyingPurchasePayment: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getBuyingUrl}/getBuyingPurchasePayment/${payload}`)
        context.commit('setBuyingPurchasePayment', data)
        return data
    },
    getBuyingPurchasePaymentTax: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getBuyingUrl}/getBuyingPurchasePaymentTax/${payload}`)
        context.commit('setBuyingPurchasePaymentTax', data)
        return data
    },
    getBuyingPurchasePaymentTaxDefault: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let data = {
            "id": 0,
            "number": "",
            "bank": '',
            "id_vendor": 0,
            "id_autonumber": '',
            "price_kurs": "0",
            "fiscal_kurs": 0,
            "id_currency": 0,
            "id_currency_bank": 0,
            "date": "0",
            "payment_method": "",
            "discount_account": '',
            "amount": 0,
            "discount": 0,
            "discount_type": '',
            "id_aol": "0",
            "id_aol_detail_invoice": 0,
            "id_aol_detail_discount": 0,
            "remarks": "",
            "status_pi": 0,
            "is_internal": 0,
            "id_branch": 0,
            "id_company_created": 0,
            "id_default": 0,
            "id_paid_transaction": 0,
            "is_tax": 1,
            "created_at": "2022-12-11T02:49:14.000000Z",
            "created_by": 0,
            "updated_at": "2022-12-11T02:49:16.000000Z",
            "updated_by": 0,
            "symbol": "Rp",
            "total": 0
          }
        context.commit('setBuyingPurchasePaymentTax', data)
        return data
    },
    savePort: async (context, payload) => {
        let {
            data
        } = await axios.post(`${context.rootState.rootApi+getBuyingUrl}`, payload)
        return data
        // context.commit('ADD_TODO',payload)
    },
    getPort: async (context, payload) => {
        try {
            let {data} = await axios.get(`${context.rootState.rootApi+getBuyingUrl+'/'+payload}`)
            context.commit('setPort', data)
						console.log('Port', data)
        } catch (error) {
            console.log(error)
            context.commit('setError', error.message);
        }
    },
    updatePort: async (context, {payload,id}) => {
        try {
            let {
                data
            } = await axios.put(`${context.rootState.rootApi+getBuyingUrl+'/'+id}`, payload)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    deletePort: async (context, id) => {
        try {
            let {
                data
            } = await axios.delete(`${context.rootState.rootApi+getBuyingUrl+'/'+id}`)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    saveBuyingDownPayment: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getBuyingUrl}/store`, payload, config)
                .then(res => {
                    setTimeout(() => commit('setBuyingDownPayment', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    setTimeout(() => commit('setBuyingDownPayment', {}), 1000)
                    reject(err)
                })
        })
    },
    saveBuyingCopy: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getBuyingUrl}/storeBuyingCopy`, payload, config)
                .then(res => {
                    setTimeout(() => commit('setBuyingDownPayment', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    setTimeout(() => commit('setBuyingDownPayment', {}), 1000)
                    reject(err)
                })
        })
    },
    updateBuyingDownPayment: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getBuyingUrl}/update`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setBuyingDownPayment', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setBuyingDownPayment', {}), 1000)
                    reject(err)
                })
        })
    },
    deleteBuyingDownPayment: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getBuyingUrl}/destroyBuyingDownPayment/${payload}`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setBuyingDownPayment', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setBuyingDownPayment', {}), 1000)
                    reject(err)
                })
        })
    },
    hardDeleteBuyingDownPayment: ({
              rootState,
              commit
          }, {
              payload,
              headers
          }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getBuyingUrl}/hardDestroyBuyingDownPayment/${payload}`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setBuyingDownPayment', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setBuyingDownPayment', {}), 1000)
                    reject(err)
                })
        })
    },
    deleteBuyingDownPaymentWithBuying: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getBuyingUrl}/destroyBuyingDownPaymentWithBuying/${payload}`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setBuyingDownPayment', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setBuyingDownPayment', {}), 1000)
                    reject(err)
                })
        })
    },
    submitAOL: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getBuyingUrl}/submitAOL/${payload}`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setBuyingDownPayment', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setBuyingDownPayment', {}), 1000)
                    reject(err)
                })
        })
    },
    submitAOLPP: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getBuyingUrl}/submitAOLPP/${payload.id}/false/${payload.idDP}`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setBuyingDownPayment', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setBuyingDownPayment', {}), 1000)
                    reject(err)
                })
        })
    },
    submitAOLPPTax: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getBuyingUrl}/submitAOLPPTax/${payload.id}/false/${payload.idDP}/true`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setBuyingDownPayment', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setBuyingDownPayment', {}), 1000)
                    reject(err)
                })
        })
    },
    updateBuying: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getBuyingUrl}/updatePI`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setBuying', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setBuying', {}), 1000)
                    reject(err)
                })
        })
    },
    updateBuyingItem: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getBuyingUrl}/updateItem`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setBuying', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setBuying', {}), 1000)
                    reject(err)
                })
        })
    },
    saveNewBuyingItem: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getBuyingUrl}/storeNewItem`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setBuying', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setBuying', {}), 1000)
                    reject(err)
                })
        })
    },
    deleteBuyingItem: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getBuyingUrl}/destroyItem/${payload.id_buying}`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setBuying', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setBuying', {}), 1000)
                    reject(err)
                })
        })
    },
    deleteBuying: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getBuyingUrl}/destroyBuying/${payload}`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setBuying', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setBuying', {}), 1000)
                    reject(err)
                })
        })
    },
    submitAOLPI: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getBuyingUrl}/submitAOLPI/${payload}`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setBuying', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setBuying', {}), 1000)
                    reject(err)
                })
        })
    },
    finishDownPayment: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getBuyingUrl}/finishDownPayment/${payload}`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setBuyingDownPayment', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setBuyingDownPayment', {}), 1000)
                    reject(err)
                })
        })
    },

    getListDpByVendor: async (context, payload) => {
		let {
			data
		} = await axios.post(`${context.rootState.rootApi+ getBuyingUrl}/getListDpByVendor/${payload.payload.vendor}/${payload.payload.currency}`, payload)
		context.commit('setListDpByVendor', data)
		return data
	},
    // getListDpByVendor: ({
    //     context,
    //     rootState,
    //     commit
    // }, {
    //     payload,
    //     headers
    // }) => {
    //     let config = {
    //         headers: headers
    //     }
    //     console.log('isi rootstate',rootState)
    //     // commit('')
    //     // return new Promise((resolve, reject) => {
    //         return axios.post(`${rootState.rootApi + getBuyingUrl}/getListDpByVendor/${payload.vendor}/${payload.currency}`, payload, config)
    //             .then(res => {
    //                 context.commit('setListDpByVendor', res)
    //                 // resolve(res)
    //             }).catch(err => {
    //                 // setTimeout(() => commit('setBuyingDownPayment', {}), 1000)
    //                 // reject(err)
    //             })
    //     // })
    // },
}
