import axios from 'axios'

let getJobOrderUrl = '/job-order';

export const state = {
	generalInfo: [],
	createdField: [],
	errors: {},
	isLoadingQuotation: false
}
export const getters = {

}
export const mutations = {
	setCreatedField: (state, payload) => {
		state.createdField = payload
	},
	setGeneralInfo: (state, payload) => {
		state.generalInfo = payload
	},
	setDetailsJobOrder: (state, payload) => {
		state.dJobOrder = payload
	},
	setListMaster: (state, payload) => {
		state.listMaster = payload
	},
	setListCustomer: (state, payload) => {
		state.listCustomer = payload
	},
	setError: (state, payload) => {
		state.errors = payload
	},
	setLoadingQuotation: (state, payload) => {
		state.isLoadingQuotation = payload
	}
}
export const actions = {
	getCreatedField: async (context, payload) => {
		let {
			data
		} = await axios.get(`${context.rootState.rootApi + getJobOrderUrl + '/created-field/' + payload}`)
		context.commit('setCreatedField', data)
		return data
	},

	getGeneralInfo: async (context, payload) => {
		let {
			data
		} = await axios.get(`${context.rootState.rootApi + getJobOrderUrl + '/item/' + payload}`)
		context.commit('setGeneralInfo', data)
		return data
	},

	getListMaster: async (context, payload) => {
		let { data } = await axios.get(`${context.rootState.rootApi + getJobOrderUrl + '/get-master/' + payload}`,)
		context.commit('setListMaster', data)
		return data
	},

	getListCustomer: async (context, payload) => {
		let { data } = await axios.get(`${context.rootState.rootApi + getJobOrderUrl + '/get-master/select_master_customer' + '?' + payload}`,)
		context.commit('setListCustomer', data)
		return data
	},
	getListVendor: async (context, payload) => {
		let { data } = await axios.get(`${context.rootState.rootApi + getJobOrderUrl + '/get-master/select_master_vendor' + '?' + payload}`,)
		context.commit('setListCustomer', data)
		return data
	},
	getListSales: async (context, payload) => {
		let { data } = await axios.get(`${context.rootState.rootApi + getJobOrderUrl + '/get-master/select_sales' + '?' + payload}`,)
		context.commit('setListCustomer', data)
		return data
	},

	storeJobOrder: async (context, payload) => {
		let {
			data
		} = await axios.post(`${context.rootState.rootApi + getJobOrderUrl + '/store'}`, payload)
		return data
	},
	updateJobOrder: async (context, payload) => {
		try {
			let {
				data
			} = await axios.put(`${context.rootState.rootApi + getJobOrderUrl + '/update'}`, payload)
			return data
		} catch (error) {
			console.log(error)
			context.commit('setError', error)
		}
	},
	detailsJobOrder: async (context, id) => {
		try {
			let {
				data
			} = await axios.get(`${context.rootState.rootApi + getJobOrderUrl + '/details/' + id}`)
			context.commit('setDetailsJobOrder', data)
			return data
		} catch (error) {
			console.log(error)
			context.commit('setError', error)
		}
	},
	deleteJobOrder: async (context, id) => {
		try {
			let {
				data
			} = await axios.delete(`${context.rootState.rootApi + getJobOrderUrl + '/delete/' + id}`)
			return data
		} catch (error) {
			console.log(error)
			context.commit('setError', error)
		}
	},

	storeJobOrderInfomation: async (context, payload) => {
		let {
			data
		} = await axios.post(`${context.rootState.rootApi + getJobOrderUrl + '/store-information'}`, payload)
		return data
	},
	detailsJobOrderInfomation: async (context, id) => {
		try {
			let {
				data
			} = await axios.get(`${context.rootState.rootApi + getJobOrderUrl + '/information/' + id}`)
			context.commit('setDetailsJobOrder', data)
			return data
		} catch (error) {
			console.log(error)
			context.commit('setError', error)
		}
	},
	branchJobOrderDefault: async (context, id) => {
		let {
			data
		} = await axios.get(`${context.rootState.rootApi + getJobOrderUrl + '/flexmod-branch/' + id}`)
		context.commit('branch flexmod', data)
		return data
	},
}
