import axios from 'axios'

let document = '/document';

export const state = {
    listDocument: [],
    listDocCode: [],
    listTaxTypeBuying: [],
    dataSingleBuying: {},
    dataItemBuying: {},
    errors: {}
}
export const getters = {

}
export const mutations = {
    setlistDocument: (state, payload) => {
        state.listDocument = payload
    },
}
export const actions = {
    getlistDocument: async (context, id) => {

        let {
            data
        } = await axios.get(`${context.rootState.rootApi + document + '/document-set-general/' + id}`)
        context.commit('getDocument', data)
        return data
    },
    saveDocument: async (context, payload) => {

        let {
            data
        } = await axios.post(`${context.rootState.rootApi + document}`, payload)
        return data
        // context.commit('ADD_TODO',payload)
    },

    updatePort: async (context, { payload, id }) => {
        try {
            let {
                data
            } = await axios.put(`${context.rootState.rootApi + document + '/' + id}`, payload)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    deleteDocument: async (context, id) => {
        try {
            let {
                data
            } = await axios.delete(`${context.rootState.rootApi + document + '/delete/' + id}`)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
}