import axios from 'axios'
// import {
// 	response
// } from 'express'

export const state = {
	currentUser: getSavedState('auth.currentUser'),
	currentToken: getAccessToken(),
}

export const mutations = {
	SET_CURRENT_USER(state, newValue) {
		state.currentUser = newValue
		// console.log(newValue);
		saveState('auth.currentUser', newValue)
		setDefaultAuthHeaders(state)
	},
	SET_ACCESS_TOKEN(state, newValue) {
		state.currentToken = newValue
		saveToken(newValue)
		setDefaultAuthHeaders(state)
	},
}

export const getters = {
	// Whether the user is currently logged in.
	loggedIn(state) {
		return !!state.currentUser
	},
}

export const actions = {
	// This is automatically run in `src/state/store.js` when the app
	// starts, along with any other actions named `init` in other modules.
	init({
		state,
		dispatch
	}) {
		setDefaultAuthHeaders(state)
		dispatch('validate')
	},

	logInCode({
		commit,
		dispatch,
		getters,
		rootState
	}, {
		code
	} = {}) {
		if (getters.loggedIn) return dispatch('validate')
		return axios
			.post(rootState.rootApi + '/loginCode', { code })
			.then(response => {
				return response
			})
			.catch((error) => {
				return error
			})
	},
	revokeSessionCode({
		commit,
		dispatch,
		getters,
		rootState
	}, {
		code
	} = {}) {
		if (getters.loggedIn) return dispatch('validate')
		return axios
			.post(rootState.rootApi + '/revokeSessionCode', { code })
			.then(response => {
				return response
			})
			.catch((error) => {
				return error
			})
	},

	// Logs in the current user.
	logIn({
		commit,
		dispatch,
		getters,
		rootState
	}, {
		code,
		email,
		password,
		accessToken
	} = {}) {
		if (getters.loggedIn) return dispatch('validate')

		let loginData = {
			code: code,
			email: email,
			password: password,
			accessToken: accessToken
		};

		if (email != null && password != null) {
			console.log('username&pass ada')

			return axios

				.post(rootState.rootApi + '/login', loginData)
				.then((response) => {
					const user = response.data
					console.log(user, 'data login');

					if (user.result === "session_active") {
						// jika session active
						return axios
							.post(rootState.rootApi + '/revokeSession', loginData)
							.then((response) => {
								// lanjut login
								return axios
									.post(rootState.rootApi + '/login', loginData)
									.then((response) => {
										const user = response.data
										console.log(user, 'data login');
										commit('SET_ACCESS_TOKEN', user)
										commit('SET_CURRENT_USER', user)
										return user
									})
							})
					} else {

						commit('SET_ACCESS_TOKEN', user)
						commit('SET_CURRENT_USER', user)
						return user
					}


				}).catch((error) => {
					console.log(error.result)
				})
			// .post(rootState.rootApi+'/login', loginData)
		} else if (code != null) {
			console.log('pake rnd')
			return axios

				.post(rootState.rootApi + '/loginCode', loginData)
				.then((response) => {
					const user = response.data
					console.log(user, 'data login')
					if (user.result === "session_active") {
						// jika session active
						return axios
							.post(rootState.rootApi + '/revokeSession', loginData)
							.then((response) => {
								// lanjut login
								return axios
									.post(rootState.rootApi + '/login', loginData)
									.then((response) => {
										const user = response.data
										console.log(user, 'data login');
										commit('SET_ACCESS_TOKEN', user)
										commit('SET_CURRENT_USER', user)
										return user
									})
							})
					} else {

						commit('SET_ACCESS_TOKEN', user)
						commit('SET_CURRENT_USER', user)
						return user
					}
					// commit('SET_ACCESS_TOKEN', user)
					// commit('SET_CURRENT_USER', user)
					// return user
				}).catch((error) => {
					console.log(error.result)
				})
		} else if (accessToken != null) {
			console.log('tanpa code, user, dan password')
			console.log(accessToken, 'dapet access tokennya')
			commit('SET_ACCESS_TOKEN', accessToken)

		}
		// return axios
		//   .post(rootState.rootApi+'/api/oauth/token', loginData)
		//   .then((response) => {
		//     const user = response.data
		//     commit('SET_ACCESS_TOKEN', user)
		//     commit('SET_CURRENT_USER', user)
		//     return user
		//   })
	},



	// Logs out the current user.
	logOut({
		commit
	}) {
		window.localStorage.removeItem('access_token')
		commit('SET_CURRENT_USER', null)
		commit('SET_ACCESS_TOKEN', null)
	},

	// register the user
	register({
		commit,
		dispatch,
		getters
	}, {
		fullname,
		email,
		password
	} = {}) {
		if (getters.loggedIn) return dispatch('validate')

		return axios
			.post('/api/register', {
				fullname,
				email,
				password
			})
			.then((response) => {
				const user = response.data
				return user
			})
	},

	// register the user
	resetPassword({
		commit,
		dispatch,
		getters
	}, {
		email
	} = {}) {
		if (getters.loggedIn) return dispatch('validate')

		return axios
			.post('/api/reset', {
				email
			})
			.then((response) => {
				const message = response.data
				return message
			})
	},

	// Validates the current user's token and refreshes it
	// with new data from the API.
	validate({
		commit,
		state,
		rootState
	}) {
		if (!state.currentToken) return Promise.resolve(null)

		try {
			const token = state.currentToken
			// commit('SET_ACCESS_TOKEN', token)
			saveToken(token)
			// setDefaultAuthHeaders(state)
			return token
		} catch (error) {
			console.log(error)
			return null
		}
	},
}

// ===
// Private helpers
// ===

function getSavedState(key) {
	return JSON.parse(window.localStorage.getItem(key))
}

function getAccessToken() {
	return window.localStorage.getItem('access_token')
}

function saveState(key, state) {
	window.localStorage.setItem(key, JSON.stringify(state))
}

function saveToken(token) {
	window.localStorage.setItem('access_token', token)
}

function setDefaultAuthHeaders(state) {
	// console.log(state.currentToken);
	// console.log(state.currentToken[0].access_token,'get state access_token')
	// axios.defaults.headers.common.Authorization = state.currentToken == null ? '' :
	// 	'Bearer ' + state.currentToken.access_token
}
