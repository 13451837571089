import axios from 'axios'

let getVesselUrl = '/vessel';

export const state = {
    listVessel: [],
    listVesselAll: [],
    dataVessel: [],
    errors: {}
}
export const getters = {

}
export const mutations = {
    setListVessel: (state, payload) => {
        state.listVessel = payload
    },
    setListVesselAll: (state, payload) => {
        state.listVesselAll = payload
    },
    setVessel: (state, payload) => {
        state.dataVessel = payload
    },
    setError: (state, payload) => {
        state.errors = payload
    }
}
export const actions = {
    getListVessel: async (context, payload) => {
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getVesselUrl}/get-vessel`)
        context.commit('setListVessel', data)
        return data
    },
    getListVesselAll: async (context, payload) => {
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getVesselUrl}/get-vessel-all`)
        context.commit('setListVesselAll', data)
        return data
    },
    getVesselById: async (context, id) => {
        try {
            let {data} = await axios.get(`${context.rootState.rootApi+getVesselUrl+'/getVessel/'+id}`)
            context.commit('setVessel', data)
			console.log('setVessel', data)
        } catch (error) {
            console.log(error)
            context.commit('setError', error.message);
        }
    },
}
