import axios from 'axios'

let getCompanyUrl = '/company';

export const state = {
    listCompany: [],
    dataCompany: {},
    companyIsAol: 0,
    companyIsUsingPPH: false,
    errors: {}
}
export const getters = {

}
export const mutations = {
    setListCompany: (state, payload) => {
        state.listCompany = payload
    },
    setCompany: (state, payload) => {
        state.dataCompany = payload
    },
    setIsAol: (state, payload) => {
        state.companyIsAol = payload
    },
    setIsUsingPPH: (state, payload) => {
        state.companyIsUsingPPH = payload
    },
    setError: (state, payload) => {
        state.errors = payload
    }
}
export const actions = {
    getListCompany: async (context, payload) => {
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getCompanyUrl}`)
        context.commit('setListCompany', data)
        // console.log('Company', data)
        return data
    },
    saveCompany: async (context, payload) => {
        let {
            data
        } = await axios.post(`${context.rootState.rootApi+getCompanyUrl}`, payload)
        return data
        // context.commit('ADD_TODO',payload)
    },
    getCompany: async (context, payload) => {
        try {
            let {data} = await axios.get(`${context.rootState.rootApi+getCompanyUrl+'/'+payload}`)
            context.commit('setCompany', data)
        } catch (error) {
            console.log(error)
            context.commit('setError', error.message);
        }
    },
    updateCompany: async (context, {payload,id}) => {
        try {
            let {
                data
            } = await axios.put(`${context.rootState.rootApi+getCompanyUrl+'/'+id}`, payload)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    deleteCompany: async (context, id) => {
        try {
            let {
                data
            } = await axios.delete(`${context.rootState.rootApi+getCompanyUrl+'/'+id}`)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    isAol: async (context, payload) => {
        try {
            let {data} = await axios.get(`${context.rootState.rootApi+'/is_aol'}`)
            context.commit('setIsAol', data)
        } catch (error) {
            console.log(error)
            context.commit('setError', error.message);
        }
    },
    isUsingPPH: async (context, payload) => {
        try {
            let {data} = await axios.get(`${context.rootState.rootApi+'/is_used_pph'}`)
            context.commit('setIsUsingPPH', data)
        } catch (error) {
            console.log(error)
            context.commit('setError', error.message);
        }
    }
}
