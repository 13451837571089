import axios from 'axios'

let getUserUrl = '/user';

export const state = {
	listUser: [],
	listUserAll: [],
	listMarketingByBranchData1: [],
	listMarketingByBranchData2: [],
	listMarketingByPermissions: [],
	listFlexmod: null,
	dataUser: {},
	errors: {}
}
export const getters = {

}
export const mutations = {
	setListUser: (state, payload) => {
		state.listUser = payload
	},
	setListUserAll: (state, payload) => {
		state.listUserAll = payload
	},
	setListMarketingByBranchData1: (state, payload) => {
		state.listMarketingByBranchData1 = payload
	},
	setListMarketingByBranchData2: (state, payload) => {
		state.listMarketingByBranchData2 = payload
	},
	setListMarketingByPermissions: (state, payload) => {
		state.listMarketingByPermissions = payload
	},
	setUser: (state, payload) => {
		state.dataUser = payload
	},
	setListFlexmod: (state, payload) => {
		state.listFlexmod = payload
	},
	setError: (state, payload) => {
		state.errors = payload
	}
}
export const actions = {
	getListUser: async (context, payload) => {
		let {
			data
		} = await axios.get(`${context.rootState.rootApi + getUserUrl + '?salesman=1'}`,)
		context.commit('setListUser', data.result)
		console.log('User', data.result)
		return data
	},
	getListUserAll: async (context, payload) => {
		let {
			data
		} = await axios.get(`${context.rootState.rootApi + getUserUrl + '/list'}`,)
		context.commit('setListUserAll', data.result)
		console.log('User', data.result)
		return data
	},
	getListMarketingByPermissions: async (context, payload) => {
		// console.log(payload,'payload');
		let {data} = await axios.get(`${context.rootState.rootApi + '/userByPermission' + '?' + payload}`,)
		context.commit('setListMarketingByPermissions', data.result)
		console.log(data.result,'datasss');
		return data
	},
	getListMarketingByBranchData1: async (context, payload) => {
		let {data} = await axios.get(`${context.rootState.rootApi + '/userByBranch' + '?' + payload}`,)
		context.commit('setListMarketingByBranchData1', data.result)
		return data
	},
	getListMarketingByBranchData2: async (context, payload) => {
		let {data} = await axios.get(`${context.rootState.rootApi + '/userByBranch' + '?' + payload}`,)
		context.commit('setListMarketingByBranchData2', data.result)
		return data
	},
	saveUser: async (context, payload) => {
		let {
			data
		} = await axios.post(`${context.rootState.rootApi + getUserUrl}`, payload)
		return data
		// context.commit('ADD_TODO',payload)
	},
	getUsername: async (context, payload) => {
		try {
			console.log('username', payload)
			let { data } = await axios.get(`${context.rootState.rootApi + getUserUrl + '/' + payload}`)
			context.commit('setUser', data.result)
			console.log('data user', data.result)
		} catch (error) {
			console.log(error)
			context.commit('setError', error.message);
		}
	},
	updateUser: async (context, { payload, id }) => {
		try {
			let {
				data
			} = await axios.put(`${context.rootState.rootApi + getUserUrl + '/' + id}`, payload)
			return data
		} catch (error) {
			console.log(error)
			context.commit('setError', error)
		}
	},
	deleteUser: async (context, id) => {
		try {
			let {
				data
			} = await axios.delete(`${context.rootState.rootApi + getUserUrl + '/' + id}`)
			return data
		} catch (error) {
			console.log(error)
			context.commit('setError', error)
		}
	},

	getUserRelease: async (context, id) => {
		try {
			let {
				data
			} = await axios.get(`${context.rootState.rootApi + '/release'}`)
			return data
		} catch (error) {
			console.log(error)
			context.commit('setError', error)
		}
	},
	getUserReleaseUpdate: async (context, id) => {
		try {
			let {
				data
			} = await axios.get(`${context.rootState.rootApi + '/release/update'}`)
			return data
		} catch (error) {
			console.log(error)
			context.commit('setError', error)
		}
	},
	getUserLogin: async (context, id) => {
		try {
			let {
				data
			} = await axios.get(`${context.rootState.rootApi + '/release/user_login'}`)
			return data
		} catch (error) {
			console.log(error)
			context.commit('setError', error)
		}
	},
	getListFlexmod: async (context, payload) => {
		let {
			data
		} = await axios.get(`${context.rootState.rootApi + '/getFlexmods'}`,)
		context.commit('setListFlexmod', data)
		return data
	},
}
