import axios from 'axios'

let getSellingUrl = '/sellingDownPayment';

export const state = {
    listSellingDownPayment: [],
    dataSingleSellingDownPayment: {},
    errors: {}
}
export const getters = {

}
export const mutations = {
    setListSellingDownPayment: (state, payload) => {
        state.listSellingDownPayment = payload
    },
    setSellingDownPayment: (state, payload) => {
        state.dataSingleSellingDownPayment = payload
    },
    setError: (state, payload) => {
        state.errors = payload
    }
}
export const actions = {
    getListSellingDownPayment: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getSellingUrl}/getSellingDownPaymentList?id_job_order=${payload}`)
        context.commit('setListSellingDownPayment', data)
        return data
    },
    getListSellingDownPaymentBySelling: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getSellingUrl}/getSellingDownPaymentListBySelling?id_job_order_selling=${payload}`)
        context.commit('setListSellingDownPayment', data)
        return data
    },
    getSellingDownPayment: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getSellingUrl}/getSellingDownPayment/${payload}`)
        context.commit('setSellingDownPayment', data)
        return data
    },
    savePort: async (context, payload) => {
        let {
            data
        } = await axios.post(`${context.rootState.rootApi+getSellingUrl}`, payload)
        return data
        // context.commit('ADD_TODO',payload)
    },
    getPort: async (context, payload) => {
        try {
            let {data} = await axios.get(`${context.rootState.rootApi+getSellingUrl+'/'+payload}`)
            context.commit('setPort', data)
						console.log('Port', data)
        } catch (error) {
            console.log(error)
            context.commit('setError', error.message);
        }
    },
    updatePort: async (context, {payload,id}) => {
        try {
            let {
                data
            } = await axios.put(`${context.rootState.rootApi+getSellingUrl+'/'+id}`, payload)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    deletePort: async (context, id) => {
        try {
            let {
                data
            } = await axios.delete(`${context.rootState.rootApi+getSellingUrl+'/'+id}`)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    saveSellingDownPayment: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getSellingUrl}/store`, payload, config)
                .then(res => {
                    setTimeout(() => commit('setSellingDownPayment', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    setTimeout(() => commit('setSellingDownPayment', {}), 1000)
                    reject(err)
                })
        })
    },
    saveSellingCopy: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getSellingUrl}/storeSellingCopy`, payload, config)
                .then(res => {
                    setTimeout(() => commit('setSellingDownPayment', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    setTimeout(() => commit('setSellingDownPayment', {}), 1000)
                    reject(err)
                })
        })
    },
    updateSellingDownPayment: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getSellingUrl}/update`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setSellingDownPayment', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setSellingDownPayment', {}), 1000)
                    reject(err)
                })
        })
    },
    deleteSellingDownPayment: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getSellingUrl}/destroySellingDownPayment/${payload}`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setSellingDownPayment', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setSellingDownPayment', {}), 1000)
                    reject(err)
                })
        })
    },
    deleteSellingDownPaymentWithSelling: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getSellingUrl}/destroySellingDownPaymentWithSelling/${payload}`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setSellingDownPayment', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setSellingDownPayment', {}), 1000)
                    reject(err)
                })
        })
    },
    submitAOL: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getSellingUrl}/submitAOL/${payload}`, payload, config)
                .then(res => {
                    // setTimeout(() => commit('setSellingDownPayment', {}), 1000)
                    resolve(res)
                }).catch(err => {
                    // setTimeout(() => commit('setSellingDownPayment', {}), 1000)
                    reject(err)
                })
        })
    },
}
