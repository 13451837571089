import axios from 'axios'

let getFreightUrl = '/api/freight';

export const state = {
    listFreight: [],
    dataFreight: {},
    errors: {}
}
export const getters = {
    
}
export const mutations = {
    setListdFreight: (state, payload) => {
        state.listFreight = payload
    },
    setdFreight: (state, payload) => {
        state.dataFreight = payload
    },
    setError: (state, payload) => {
        state.errors = payload
    }
}
export const actions = {
    getListFreight: async (context, payload) => {
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getFreightUrl}`)
        context.commit('setListdFreight', data.data)
        console.log('dFreight', data)
        return data
    },
    saveFreight: async (context, payload) => {
        let {
            data
        } = await axios.post(`${context.rootState.rootApi+getFreightUrl}`, payload)
        return data
        // context.commit('ADD_TODO',payload)
    },
    getFreight: async (context, payload) => {
        try {
            let {data} = await axios.get(`${context.rootState.rootApi+getFreightUrl+'/'+payload}`)
            context.commit('setdFreight', data.data)
        } catch (error) {
            console.log(error)
            context.commit('setError', error.message);
        }
    },
    updateFreight: async (context, {payload,id}) => {
        try {
            let {
                data
            } = await axios.put(`${context.rootState.rootApi+getFreightUrl+'/'+id}`, payload)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    deleteFreight: async (context, id) => {
        try {
            let {
                data
            } = await axios.delete(`${context.rootState.rootApi+getFreightUrl+'/'+id}`)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    }
}