import axios from 'axios'
// import { head } from 'lodash';

let getCustomerHistoryUrl = '/customer/history';

export const state = {
		listCustomerHistory: [],
		listScheduleCustomer:[],
    dataCustomerHistory: [],
    isLoadingCustomerHistory: false,
    errors: {}
}
export const getters = {

}
export const mutations = {
    setListCustomerHistory: (state, payload) => {
        state.listCustomerHistory = payload
		},
		setScheduleCustomer: (state, payload) => {
			state.listScheduleCustomer = payload
	},
    setCustomerHistory: (state, payload) => {
        state.dataCustomerHistory = payload
    },
    setError: (state, payload) => {
        state.errors = payload
    },
    setCustomerHistoryLoading: (state, payload) => {
        state.isLoadingCustomerHistory = payload
    }
}
export const actions = {
    getListCustomerHistory: async (context, payload) => {
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getCustomerHistoryUrl + '/list'}`)
        context.commit('setListCustomerHistory',data)
        console.log('csutomer',data)
        return data
		},

		getScheduleCustomer: async (context, payload) => {
			let {
					data
			} = await axios.get(`${context.rootState.rootApi+getCustomerHistoryUrl + '/schedule'}`)
			context.commit('setScheduleCustomer',data)
			console.log('schedule data',data)
			return data
	},
    // saveCustomerHistory: async (context, payload) => {
    //     let {
    //         data
    //     } = await axios.post(`${context.rootState.rootApi+getCustomerHistoryUrl}`, payload)
    //     return data
    //     // context.commit('ADD_TODO',payload)
    // },

    saveCustomerHistory: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }

        commit('setCustomerHistoryLoading', true)
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi+getCustomerHistoryUrl}`, payload, config)
                .then(res => {
                    commit('setCustomerHistoryLoading', false)

                    setTimeout(() => commit('setCustomerHistory', {}), 1000)
                    resolve(res)
                    console.log('save cust', res)
                }).catch(err => {
                    commit('setCustomerHistoryLoading', false)
                    setTimeout(() => commit('setCustomerHistory', {}), 1000)
                    reject(err)
                })

        })
    },

    getCustomerHistory: async (context, payload) => {
        try {
            let {data} = await axios.get(`${context.rootState.rootApi+getCustomerHistoryUrl+'/get/'+payload}`)
            context.commit('setCustomerHistory',data)
            console.log('getCustomerHistory', data)
        } catch (error) {
            console.log(error)
            context.commit('setError', error.message);
        }
    },
    updateCustomerHistory: async (context, {payload,id}) => {
        try {
            console.log('payload', payload)
            console.log('id', id)
            let {
                data
            } = await axios.post(`${context.rootState.rootApi+getCustomerHistoryUrl+'/'+id}`, payload)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    deleteCustomerHistory: async (context, id) => {
        try {
            let {
                data
            } = await axios.delete(`${context.rootState.rootApi+getCustomerHistoryUrl+'/'+id}`)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    }
}
