import axios from 'axios'

let getPercentageUrl = '/percentage';

export const state = {
    listPercentage: [],
    listPPH: [],
    dataPort: {},
    errors: {}
}
export const getters = {

}
export const mutations = {
    setListPercentage: (state, payload) => {
        state.listPercentage = payload
    },
    setListPPH: (state, payload) => {
        state.listPPH = payload
    },
    setPort: (state, payload) => {
        state.dataPort = payload
    },
    setError: (state, payload) => {
        state.errors = payload
    }
}
export const actions = {
    getListPercentage: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getPercentageUrl}/getPercentage`)
        context.commit('setListPercentage', data)
        return data
    },
    getListPPH: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getPercentageUrl}/getListPPH`)
        context.commit('setListPPH', data)
        return data
    },
    savePort: async (context, payload) => {
        let {
            data
        } = await axios.post(`${context.rootState.rootApi+getPercentageUrl}`, payload)
        return data
        // context.commit('ADD_TODO',payload)
    },
    getPort: async (context, payload) => {
        try {
            let {data} = await axios.get(`${context.rootState.rootApi+getPercentageUrl+'/'+payload}`)
            context.commit('setPort', data)
						console.log('Port', data)
        } catch (error) {
            console.log(error)
            context.commit('setError', error.message);
        }
    },
    updatePort: async (context, {payload,id}) => {
        try {
            let {
                data
            } = await axios.put(`${context.rootState.rootApi+getPercentageUrl+'/'+id}`, payload)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    deletePort: async (context, id) => {
        try {
            let {
                data
            } = await axios.delete(`${context.rootState.rootApi+getPercentageUrl+'/'+id}`)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    }
}
