import axios from 'axios'

let getTruckUrl = '/truck';

export const state = {
    listTruck: [],
    datTruckt: {},
    errors: {}
}
export const getters = {

}
export const mutations = {
    setListTruck: (state, payload) => {
        state.listTruck = payload
    },
    setPort: (state, payload) => {
        state.dataTruck = payload
    },
    setError: (state, payload) => {
        state.errors = payload
    }
}
export const actions = {
    getListTruck: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getTruckUrl}/getTruck`)
        context.commit('setListTruck', data)
        return data
    },

    savePort: async (context, payload) => {
        let {
            data
        } = await axios.post(`${context.rootState.rootApi+getTruckUrl}`, payload)
        return data
        // context.commit('ADD_TODO',payload)
    },
    getPort: async (context, payload) => {
        try {
            let {data} = await axios.get(`${context.rootState.rootApi+getTruckUrl+'/'+payload}`)
            context.commit('setPort', data)
						console.log('Port', data)
        } catch (error) {
            console.log(error)
            context.commit('setError', error.message);
        }
    },
    updatePort: async (context, {payload,id}) => {
        try {
            let {
                data
            } = await axios.put(`${context.rootState.rootApi+getTruckUrl+'/'+id}`, payload)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    deletePort: async (context, id) => {
        try {
            let {
                data
            } = await axios.delete(`${context.rootState.rootApi+getTruckUrl+'/'+id}`)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    }
}
