import axios from 'axios'

// let getItemUrl = '/item';
let getFavoriteUrl = '/FavoriteJobOrder';
// let getItemUrlPpn = '/item_ppn';
// let getItemSparepartInUrl = '/item-sparepartin';

export const state = {
	listItem: [],
	listItemSparepartIn: [],
	ItemPpn: [],
	dataItem: {},
	errors: {}
}
export const getters = {

}
export const mutations = {
	setListItem: (state, payload) => {
		state.listItem = payload
	},
	setListItemSparepartIn: (state, payload) => {
		state.listItemSparepartIn = payload
	},
	setItemPpn: (state, payload) => {
		state.ItemPpn = payload
	},
	setItem: (state, payload) => {
		state.dataItem = payload
	},
	setError: (state, payload) => {
		state.errors = payload
	}
}
export const actions = {
	// saveNewFavorite: async (context, payload) => {
	//     let {
	//         data
	//     } = await axios.post(`${context.rootState.rootApi+getFavoriteUrl}/store`, payload)
	//     return data
	//     // context.commit('ADD_TODO',payload)
	// },
	saveNewFavorite: ({
		rootState,
		commit
	}, {
		payload,
		headers
	}) => {
		let config = {
			headers: headers
		}
		return new Promise((resolve, reject) => {
			return axios.post(`${rootState.rootApi + getFavoriteUrl}/store`, payload, config)
				.then(res => {
					// setTimeout(() => commit('setBuying', {}), 1000)
					resolve(res)
				}).catch(err => {
					// setTimeout(() => commit('setBuying', {}), 1000)
					reject(err)
				})
		})
	},
	loadFavorite: ({
		rootState,
		commit
	}, {
		payload,
		headers
	}) => {
		let config = {
			headers: headers
		}
		return new Promise((resolve, reject) => {
			return axios.post(`${rootState.rootApi + getFavoriteUrl}/loadFav/${payload.id_job_order}/${payload.type}`, payload, config)
				.then(res => {
					// setTimeout(() => commit('setBuying', {}), 1000)
					resolve(res)
				}).catch(err => {
					// setTimeout(() => commit('setBuying', {}), 1000)
					reject(err)
				})
		})
	},
	deleteFavJobOrder: async (context, id) => {
		try {
			let {
				data
			} = await axios.delete(`${context.rootState.rootApi + getFavoriteUrl + '/destroyFav/' + id}`)
			return data
		} catch (error) {
			console.log(error)
			context.commit('setError', error)
		}
	}
}
