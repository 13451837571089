import axios from 'axios'
// import {
//     head
// } from 'lodash';
// let api = 'http://localhost:8000';
let getReasonUrl = '/reason';

export const state = {
    listReason: [],
    dataReason: {},
    errors: {},
    isLoadingReason: false
}
export const getters = {

}
export const mutations = {
    setListReason: (state, payload) => {
        state.listReason = payload
    },
    setReason: (state, payload) => {
        state.dataReason = payload
    },
    setError: (state, payload) => {
        state.errors = payload
    },
    setLoadingReason: (state, payload) => {
        state.isLoadingReason = payload
    }
}
export const actions = {
    getListReason: async (context, payload) => {
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getReasonUrl}`)
        context.commit('setListReason', data)
        console.log('Reason', data)
        return data
    },
    saveReason: ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate',rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getReasonUrl}`, payload, config)
                .then(res => {
                    setTimeout(() => commit('setReason', {}), 1000)
                    resolve(res)
                    console.log('save Reason', res)
                }).catch(err => {
                    setTimeout(() => commit('setReason', {}), 1000)
                    reject(err)
                })
        })
    },
    getReason: ({
        commit,
        rootState
    }, payload) => {
        return new Promise((resolve, reject) => {
            commit('setLoadingReason', true)
            return axios.get(`${rootState.rootApi + getReasonUrl +'/'+ payload}`)
                .then(res => {
                    commit('setReason', res.data)

                    commit('setLoadingReason', false)
                    resolve(res)

                    console.log("test data", res.data)
                }).catch(err => {
                    commit('setLoadingReason', false)
                    reject(err)
                })

        })

    },

    updateReason: async (context, {
        payload,
				id,
				headers
    }) => {
        try {
						let config = {
							headers: headers
						}
            let {
                data
            } = await axios.put(`${context.rootState.rootApi + getReasonUrl +'/'+id}`, payload, config)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    deleteReason: async (context, id) => {
        try {
            let {
                data
            } = await axios.delete(`${context.rootState.rootApi + getReasonUrl+'/'+id}`)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    }
}
