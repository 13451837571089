import axios from 'axios'

let getUserNotifUrl = '/notif';

export const state = {
	listUserNotif: [],
	errors: {}
}
export const getters = {}
export const mutations = {
	setListUserNotif: (state, payload) => {
		state.listUserNotif = payload
	},
	setError: (state, payload) => {
		state.errors = payload
	}
}
export const actions = {
	getListUserNotif: async (context, payload) => {
		let {
			data
		} = await axios.get(`${context.rootState.rootApi + getUserNotifUrl}`)
		context.commit('setListUserNotif', data)
		console.log('UserNotif', data)
		return data
	}
}
