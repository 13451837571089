import axios from 'axios'

let getTimelineUrl = '/status-shipment';

export const state = {
    listTimeline: [],
    listDataTimeline: [],
    errors: {}
}
export const getters = {

}
export const mutations = {
    setListTimeline: (state, payload) => {
        state.listTimeline = payload
    },
    setListDataTimeline: (state, payload) => {
        state.listDataTimeline = payload
    },
    setError: (state, payload) => {
        state.errors = payload
    }
}
export const actions = {
    getListTimeline: async (context, payload) => {
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getTimelineUrl}`)
        context.commit('setListTimeline', data)
        return data
    },
    getTimeline: async (context, payload) => {
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getTimelineUrl}/get-timeline/` + payload)
        context.commit('setListDataTimeline', data)
        return data
    },
    saveTimeline: async (context, payload) => {
        let {
            data
        } = await axios.post(`${context.rootState.rootApi+getTimelineUrl}/update-timeline`, payload)
        // context.commit('setListTimeline', data)
        return data
    },
}
