<script>
import Layout from '@layouts/main'

export default {
  page: {
    title: 'Page timeout',
    meta: [
      { name: 'description', content: 'The page timed out while loading.' },
    ],
  },
  components: { Layout },
  mounted() {
    this.reloadPage()
  },
  methods: {
    reloadPage() {
      var countReload = localStorage.getItem('errorReload');
      if (isNaN(countReload)) {
        countReload = 0;
      }
      if (countReload < 2) {
        localStorage.setItem('errorReload', parseInt(countReload) + 1)
        this.$router.go()
      } else {
        localStorage.setItem('errorReload', 0)
      }
    }
  },
}
</script>

<template>
  <Layout>
    <h1 :class="$style.title">
      The page timed out while loading. Are you sure you're still connected to
      the Internet?
    </h1>
  </Layout>
</template>

<style lang="scss" module>
.title {
  text-align: center;
}
</style>
