import axios from 'axios'
// import { head } from 'lodash';

let getCustomerUrl = '/customer';

var admin = ''

if(JSON.parse(localStorage.getItem('userPermissions'))){
    for (let i = 0; i < JSON.parse(localStorage.getItem('userPermissions')).length; i++) {

        if (JSON.parse(localStorage.getItem('userPermissions'))[i] === 'CRM_ADMIN_ENABLE') {
            admin = '&admin=true';
        }

    }
}




export const state = {
	listCustomer: [],
	listCustomers: [],
	listCustomerByBranch: [],
	listCustomerQuo: [],
	listCustomerDedicated: [],
	listCustomerVisit: [],
	listCustomerClosing: [],
	listCustomerPending: [],
	listCustomerReject: [],
	dataCustomer: {},
	dataChart1: {},
	dataChart2: {},
	dataChart3: {},
	dataChart4: {},
	dataChart5: {},
	dataDashboardCustomer: [],
	isLoadingCustomer: false,
	errors: {}
}
export const getters = {

}
export const mutations = {
	setListCustomer: (state, payload) => {
		state.listCustomer = payload
	},
	setListCustomers: (state, payload) => {
		state.listCustomers = payload
	},
	setListCustomerByBranch: (state, payload) => {
		state.listCustomerByBranch = payload
	},
	setListCustomerQuo: (state, payload) => {
		state.listCustomerQuo = payload
	},
	setListCustomerDedicated: (state, payload) => {
		state.listCustomerDedicated = payload
	},
	setListCustomerVisit: (state, payload) => {
		state.listCustomerVisit = payload
	},
	setListCustomerClosing: (state, payload) => {
		state.listCustomerClosing = payload
	},
	setListCustomerPending: (state, payload) => {
		state.listCustomerPending = payload
	},
	setListCustomerReject: (state, payload) => {
		state.listCustomerReject = payload
	},
	setCustomer: (state, payload) => {
		state.dataCustomer = payload
	},
	setChart1: (state, payload) => {
		state.dataChart1 = payload
	},
	setChart2: (state, payload) => {
		state.dataChart2 = payload
	},
	setChart3: (state, payload) => {
		state.dataChart3 = payload
	},
	setChart4: (state, payload) => {
		state.dataChart4 = payload
	},
	setChart5: (state, payload) => {
		state.dataChart5 = payload
	},
	setError: (state, payload) => {
		state.errors = payload
	},
	setCustomerLoading: (state, payload) => {
		state.isLoadingCustomer = payload
	},
	setDashboardCustomer: (state, payload) => {
		state.dataDashboardCustomer = payload
	},
}
export const actions = {
	getListCustomer: async (context, payload) => {

		let {
			data
		} = await axios.get(`${context.rootState.rootApi+getCustomerUrl + '/list'}`)
		
		context.commit('setListCustomer', data)
		console.log('csutomer', data)
		return data
	},

	getListCustomerByBranch: async (context, payload) => {
		let {
			data
		} = await axios.get(`${context.rootState.rootApi+'/customerByBranch'}`)
		context.commit('setListCustomerByBranch', data)
		return data
	},

	getListCustomers: async (context, payload) => {
		let {
			data
		} = await axios.get(`${context.rootState.rootApi+'/customersList'}`)
		context.commit('setListCustomers', data)
		return data
	},

	getListCustomerQuo: async (context, payload) => {
		let {
			data
		} = await axios.get(`${context.rootState.rootApi+getCustomerUrl + '/for_quo'}`)
		context.commit('setListCustomerQuo', data)
		// console.log('csutomer',data)
		return data
	},

	getListCustomerDedicated: async (context, payload) => {
		if(payload === undefined){
			payload = null
		}
		let {
			data
		} = await axios.get(`${context.rootState.rootApi+getCustomerUrl + '/list?dedicated=true' + admin + '&user='+ payload}`)

		data.forEach((dr, idx) => {
			if(dr.customer_history == null) {
				dr.customer_history = {
					trust_meter: 0,
					remark: ''
				}
			}
		});

		context.commit('setListCustomerDedicated', data)
		// console.log('setListCustomerDedicated', data)
		return data
	},

	getListCustomerVisit: async (context, payload) => {
		if(payload === undefined){
			payload = null
		}
		let {
			data
		} = await axios.get(`${context.rootState.rootApi+getCustomerUrl + '/list?visit=true' + admin  + '&user='+ payload}`)

		data.forEach((dr, idx) => {
			if(dr.customer_history == null) {
				dr.customer_history = {
					trust_meter: 0,
					remark: ''
				}
			}
		});

		context.commit('setListCustomerVisit', data)
		// console.log('setListCustomerVisit', data)
		return data
	},

	getListCustomerClosing: async (context, payload) => {
		if(payload === undefined){
			payload = null
		}
		let {
			data
		} = await axios.get(`${context.rootState.rootApi+getCustomerUrl + '/list?closing=true' + admin  + '&user='+ payload}`)

		data.forEach((dr, idx) => {
			if(dr.customer_history == null) {
				dr.customer_history = {
					trust_meter: 0,
					remark: ''
				}
			}
		});

		context.commit('setListCustomerClosing', data)
		// console.log('setListCustomerClosing', data)
		return data
	},
	getListCustomerPending: async (context, payload) => {
		if(payload === undefined){
			payload = null
		}
		let {
			data
		} = await axios.get(`${context.rootState.rootApi+getCustomerUrl + '/list?pending=true' + admin  + '&user='+ payload}`)

		data.forEach((dr, idx) => {
			if(dr.customer_history == null) {
				dr.customer_history = {
					trust_meter: 0,
					remark: ''
				}
			}
		});

		context.commit('setListCustomerPending', data)
		// console.log('setListCustomerPending', data)
		return data
	},

	getListCustomerReject: async (context, payload) => {
		if(payload === undefined){
			payload = null
		}
		let {
			data
		} = await axios.get(`${context.rootState.rootApi+getCustomerUrl + '/list?reject=true' + admin + '&user='+ payload}`)

		data.forEach((dr, idx) => {
			if(dr.customer_history == null) {
				dr.customer_history = {
					trust_meter: 0,
					remark: ''
				}
			}
		});

		context.commit('setListCustomerReject', data)
		// console.log('setListCustomerReject', data)
		return data
	},
	// saveCustomer: async (context, payload) => {
	//     let {
	//         data
	//     } = await axios.post(`${context.rootState.rootApi+getCustomerUrl}`, payload)
	//     return data
	//     // context.commit('ADD_TODO',payload)
	// },

	saveCustomer: ({
		rootState,
		commit
	}, {
		payload,
		headers
	}) => {
		let config = {
			headers: headers
		}

		commit('setCustomerLoading', true)
		return new Promise((resolve, reject) => {
			return axios.post(`${rootState.rootApi+getCustomerUrl}`, payload, config)
				.then(res => {
					commit('setCustomerLoading', false)

					setTimeout(() => commit('setCustomer', {}), 1000)
					resolve(res)
					// console.log('save cust', res)
				}).catch(err => {
					commit('setCustomerLoading', false)
					setTimeout(() => commit('setCustomer', {}), 1000)
					reject(err)
				})

		})
	},

	getCustomer: async (context, payload) => {
		try {
			let {
				data
			} = await axios.get(`${context.rootState.rootApi+getCustomerUrl+'/'+payload}`)
			context.commit('setCustomer', data)
			// console.log('getCustomer', data)
		} catch (error) {
			console.log(error)
			context.commit('setError', error.message);
		}
	},




	updateCustomer: async (context, {
		payload,
		id
	}) => {
		try {
			// console.log('payload', payload)
			// console.log('id', id)
			let {
				data
			} = await axios.put(`${context.rootState.rootApi+getCustomerUrl+'/'+id}`, payload)
			return data
		} catch (error) {
			console.log(error)
			context.commit('setError', error)
		}
	},
	deleteCustomer: async (context, id) => {
		try {
			let {
				data
			} = await axios.delete(`${context.rootState.rootApi+getCustomerUrl+'/'+id}`)
			return data
		} catch (error) {
			console.log(error)
			context.commit('setError', error)
		}
	},



	getChart1: async (context, payload) => {
		try {
			// console.log('payload',payload.createdBy)
			let config = {
				headers: {
					'candidate': 1,
				}
			}
			let {
				data
			} = await axios.get(`${context.rootState.rootApi+getCustomerUrl+'/chart?year='+payload.year + '&user='+payload.createdBy +admin}`, config)
			context.commit('setChart1', data)
			// console.log('getChart', data)
			// console.log('payload',payload)
		} catch (error) {
			console.log(error)
			context.commit('setError', error.message);
		}
	},
	getChart2: async (context, payload) => {
		try {
			let config = {
				headers: {
					'visit': 1
				}
			}
			let {
				data
			} = await axios.get(`${context.rootState.rootApi+getCustomerUrl+'/chart?year='+payload.year + '&user='+payload.createdBy + admin}`, config)
			context.commit('setChart2', data)
			// console.log('getChart2', data)
		} catch (error) {
			console.log(error)
			context.commit('setError', error.message);
		}
	},

	getChart3: async (context, payload) => {
		try {
			let config = {
				headers: {
					'closing': 1
				}
			}
			let {
				data
			} = await axios.get(`${context.rootState.rootApi+getCustomerUrl+'/chart?year='+payload.year + '&user='+payload.createdBy + admin}`, config)
			context.commit('setChart3', data)
			// console.log('getChart3', data)
		} catch (error) {
			console.log(error)
			context.commit('setError', error.message);
		}
	},

	getChart4: async (context, payload) => {
		try {
			let config = {
				headers: {
					'pending': 1
				}
			}
			let {
				data
			} = await axios.get(`${context.rootState.rootApi+getCustomerUrl+'/chart?year='+payload.year + '&user='+payload.createdBy + admin}`, config)
			context.commit('setChart4', data)
			// console.log('getChart3', data)
		} catch (error) {
			console.log(error)
			context.commit('setError', error.message);
		}
	},

	getChart5: async (context, payload) => {
		try {
			let config = {
				headers: {
					'reject': 1
				}
			}
			let {
				data
			} = await axios.get(`${context.rootState.rootApi+getCustomerUrl+'/chart?year='+payload.year + '&user='+payload.createdBy + admin}`, config)
			context.commit('setChart5', data)
			// console.log('getChart3', data)
		} catch (error) {
			console.log(error)
			context.commit('setError', error.message);
		}
	},
}
