import axios from 'axios'

let distribution = '/distribution';

export const state = {
    listDocument: [],
    typeOptions: []
}
export const getters = {

}
export const mutations = {
    setlistDocument: (state, payload) => {
        state.listDocument = payload
    },
    settypeOptions: (state, payload) => {
        state.typeOptions = payload
    },
}
export const actions = {
    getlistDocument: async (context, id) => {

        let {
            data
        } = await axios.get(`${context.rootState.rootApi + distribution + '/document-set-general/' + id}`)
        context.commit('getDocument', data)
        return data
    },
    getTypeOptions: async (context, payload) => {
        try {
            let {
                data
            } = await axios.get(`${context.rootState.rootApi + distribution + '/get-type-options/' + payload}`)
            // context.commit('settypeOptions', data, { root: true })
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    saveDistribution: async (context, payload) => {

        let {
            data
        } = await axios.post(`${context.rootState.rootApi + distribution + '/store'}`, payload)
        return data
        // context.commit('ADD_TODO',payload)
    },
    deleteJobOrderDistribution: async (context, id) => {
        try {
            let {
                data
            } = await axios.get(`${context.rootState.rootApi + distribution + '/delete/' + id}`)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },

    submitAOL: async (context, id) => {
        try {
            let {
                data
            } = await axios.get(`${context.rootState.rootApi + distribution + '/submit-aol/' + id}`)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },

    saveDistributionBackupTruck: async (context, payload) => {
        let {
            data
        } = await axios.post(`${context.rootState.rootApi + distribution + '/backup-truck'}`, payload)
        return data
        // context.commit('ADD_TODO',payload)
    },


}