import axios from 'axios'
// import {
//     head
// } from 'lodash';
// let api = 'http://localhost:8000';
let getQuotationUrl = '/quotation';

export const state = {
    listQuotation: [],
    dataQuotation: {},
    errors: {},
    isLoadingQuotation: false
}
export const getters = {

}
export const mutations = {
    setListQuotation: (state, payload) => {
        state.listQuotation = payload
    },
    setQuotation: (state, payload) => {
        state.dataQuotation = payload
    },
    setError: (state, payload) => {
        state.errors = payload
    },
    setLoadingQuotation: (state, payload) => {
        state.isLoadingQuotation = payload
    }
}
export const actions = {
    getListQuotation: async (context, payload) => {
        let {
            data
        } = await axios.get(`${context.rootState.rootApi + getQuotationUrl}`)
        context.commit('setListQuotation', data.data)
        console.log('Quotation', data)
        return data
    },
    saveQuotation: async ({
        rootState,
        commit
    }, {
        payload,
        headers
    }) => {
        let config = {
            headers: headers
        }
        console.log('isi rootstate', rootState)
        // commit('')
        return new Promise((resolve, reject) => {
            return axios.post(`${rootState.rootApi + getQuotationUrl}`, payload, config)
                .then(res => {
                    setTimeout(() => commit('setQuotation', {}), 1000)
                    resolve(res)
                    console.log('save Quotation', res)
                }).catch(err => {
                    setTimeout(() => commit('setQuotation', {}), 1000)
                    reject(err)
                })
        })
    },
    getQuotation: ({
        commit,
        rootState
    }, payload) => {
        return new Promise((resolve, reject) => {
            commit('setLoadingQuotation', true)
            return axios.get(`${rootState.rootApi + getQuotationUrl + '/' + payload}`)
                .then(res => {
                    commit('setQuotation', res.data)

                    commit('setLoadingQuotation', false)
                    resolve(res)

                    console.log("test data", res.data)
                }).catch(err => {
                    commit('setLoadingQuotation', false)
                    reject(err)
                })

        })

    },
    generatePdf: ({
        commit,
        rootState
    }, payload) => {
        return new Promise((resolve, reject) => {
            commit('setLoadingQuotation', true)
            return axios.get(`${rootState.rootApi + getQuotationUrl + '/pdf/' + payload}`)
                .then(res => {
                    // commit('setQuotation', res.data.result)

                    commit('setLoadingQuotation', false)
                    resolve(res)



                    // console.log("test data", res.data.data)
                }).catch(err => {
                    commit('setLoadingQuotation', false)
                    reject(err)
                })

            // return
            // axios({
            // 	method:'post',
            // 	url:'/reports/interval-dates',
            // 	responseType:'arraybuffer',
            // 	data: dates
            // })
            // .then(function(response) {
            // 		let blob = new Blob([response.data], { type:   'application/pdf' } );
            // 		let link = document.createElement('a');
            // 		link.href = window.URL.createObjectURL(blob);
            // 		link.download = 'Report.pdf';
            // 		link.click();
            // });

        })

    },
    updateQuotation: async (context, {
        payload,
        id,
        headers
    }) => {
        try {
            let config = {
                headers: headers
            }
            let {
                data
            } = await axios.put(`${context.rootState.rootApi + getQuotationUrl + '/' + id}`, payload, config)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    deleteQuotation: async (context, id) => {
        try {
            let {
                data
            } = await axios.delete(`${context.rootState.rootApi + getQuotationUrl + '/' + id}`)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    }
}
