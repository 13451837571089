<script>
import simplebar from 'simplebar-vue'

import { authComputed } from '@state/helpers'
import SideNav from './side-nav'
import { mapState, mapActions } from 'vuex'

export default {
  components: { simplebar, SideNav },
  props: {
    isCondensed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      settings: {
        minScrollbarLength: 60,
      },
    }
  },
  mounted: function() {
    this.getListFlexmod()
  },
  computed: {
    ...authComputed,
    ...mapState('user', ['listFlexmod'])
  },
  methods: {
    ...mapActions('user', ['getListFlexmod']),
  },
}
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="left-side-menu" v-if="listFlexmod !== null">
    <simplebar
      v-if="!isCondensed"
      :settings="settings"
      class="h-100"
    >
      <SideNav />
    </simplebar>
    <SideNav
      v-else
      :is-condensed="isCondensed"
    />

    <!-- Sidebar -left -->
  </div>
  <!-- Left Sidebar End -->
</template>

