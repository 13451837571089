import axios from 'axios'

let getWarehouseUrl = '/warehouse';

export const state = {
    listWarehouse: [],
    errors: {}
}
export const getters = {

}
export const mutations = {
    setListWarehouse: (state, payload) => {
        state.listWarehouse = payload
    },
    setError: (state, payload) => {
        state.errors = payload
    }
}
export const actions = {
    getListWarehouse: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getWarehouseUrl}`)
        context.commit('setListWarehouse', data)
        console.log('Item', data)
        return data
    }
}
