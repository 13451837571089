import axios from 'axios'

let getAirlinesUrl = '/airlines';

export const state = {
    listAirlines: [],
    listAirlinesAll: [],
    dataAirlines: [],
    errors: {}
}
export const getters = {

}
export const mutations = {
    setListAirlines: (state, payload) => {
        state.listAirlines = payload
    },
    setListAirlinesAll: (state, payload) => {
        state.listAirlinesAll = payload
    },
    setAirlines: (state, payload) => {
        state.dataAirlines = payload
    },
    setError: (state, payload) => {
        state.errors = payload
    }
}
export const actions = {
    getListAirlines: async (context, payload) => {
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getAirlinesUrl}/get-airlines`)
        context.commit('setListAirlines', data)
        return data
    },
    getListAirlinesAll: async (context, payload) => {
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getAirlinesUrl}/get-airlines-all`)
        context.commit('setListAirlinesAll', data)
        return data
    },
    getAirlinesById: async (context, id) => {
        try {
            let {data} = await axios.get(`${context.rootState.rootApi+getAirlinesUrl+'/getAirlines/'+id}`)
            context.commit('setAirlines', data)
			console.log('setAirlines', data)
        } catch (error) {
            console.log(error)
            context.commit('setError', error.message);
        }
    },
}
