import axios from 'axios'

let getUserBranchUrl = '/user_branch';

export const state = {
    listUserBranch: [],
    dataUserBranch: {},
    errors: {}
}
export const getters = {

}
export const mutations = {
    setListUserBranch: (state, payload) => {
        state.listUserBranch = payload
    },
    setUserBranch: (state, payload) => {
        state.dataUserBranch = payload
    },
    setError: (state, payload) => {
        state.errors = payload
    }
}
export const actions = {
    getListUserBranch: async (context, payload) => {
        let {
            data
        } = await axios.get(`${context.rootState.rootApi + getUserBranchUrl + '?id_order_flexmod=' + payload}`)
        context.commit('setListUserBranch', data)
        // console.log('UserBranch', data)
        return data
    },
    saveUserBranch: async (context, payload) => {
        let {
            data
        } = await axios.post(`${context.rootState.rootApi + getUserBranchUrl}`, payload)
        return data
        // context.commit('ADD_TODO',payload)
    },
    getUserBranch: async (context, payload) => {
        try {
            let { data } = await axios.get(`${context.rootState.rootApi + getUserBranchUrl + '/' + payload}`)
            context.commit('setUserBranch', data)
        } catch (error) {
            console.log(error)
            context.commit('setError', error.message);
        }
    },
    updateUserBranch: async (context, { payload, id }) => {
        try {
            let {
                data
            } = await axios.put(`${context.rootState.rootApi + getUserBranchUrl + '/' + id}`, payload)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    deleteUserBranch: async (context, id) => {
        try {
            let {
                data
            } = await axios.delete(`${context.rootState.rootApi + getUserBranchUrl + '/' + id}`)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    }
}
