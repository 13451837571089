import axios from 'axios'

let getTermUrl = '/term';

export const state = {
    listTerm: [],
    dataTerm: {},
    errors: {}
}
export const getters = {

}
export const mutations = {
    setListTerm: (state, payload) => {
        state.listTerm = payload
    },
    setTerm: (state, payload) => {
        state.dataTerm = payload
    },
    setError: (state, payload) => {
        state.errors = payload
    }
}
export const actions = {
    getListTerm: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getTermUrl}`)
        context.commit('setListTerm', data)
        console.log('Term', data)
        return data
    },
    saveTerm: async (context, payload) => {
        let {
            data
        } = await axios.post(`${context.rootState.rootApi+getTermUrl}`, payload)
        return data
        // context.commit('ADD_TODO',payload)
    },
    getTerm: async (context, payload) => {
        try {
            let {data} = await axios.get(`${context.rootState.rootApi+getTermUrl+'/'+payload}`)
            context.commit('setTerm', data)
        } catch (error) {
            console.log(error)
            context.commit('setError', error.message);
        }
    },
    updateTerm: async (context, {payload,id}) => {
        try {
            let {
                data
            } = await axios.put(`${context.rootState.rootApi+getTermUrl+'/'+id}`, payload)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    deleteTerm: async (context, id) => {
        try {
            let {
                data
            } = await axios.delete(`${context.rootState.rootApi+getTermUrl+'/'+id}`)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    }
}
