import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import VueApexCharts from 'vue-apexcharts'
import VueTour from 'vue-tour'
import Vuelidate from 'vuelidate'
import KnobControl from 'vue-knob-control'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueMask from 'v-mask'
import VueStringFilter from 'vue-string-filter'
import VueRouter from 'vue-router'
import vco from "v-click-outside"
import VueEvents from 'vue-events'
import money from 'v-money'
import VueElementLoading from "vue-element-loading"
import PrimeVue from 'primevue/config';
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css"
// import Echo from 'laravel-echo'
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules'
import id from 'vee-validate/dist/locale/id.json';
import App from './App'
import router from '@router'
import store from '@state/store'
import { parse, isValid, isAfter } from 'date-fns';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
// import mDatePicker from 'vue-multi-date-picker'
// ...
import '@components/_globals'
// import Vue from "vue";
import "vue-easytable/libs/theme-default/index.css"; // import style
import VueEasytable from "vue-easytable"; // import library

import VModal from 'vue-js-modal'
// import * as repository from './repository'
import axios from 'axios'
import Swal from "sweetalert2";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import ToggleButton from 'vue-js-toggle-button'

Vue.component('v-select', vSelect)

Vue.use(VModal)

// Vue.use(mDatePicker)
Vue.use(VueRouter)
Vue.use(vco)
Vue.use(VueEasytable);
Vue.use(ToggleButton)

let vueMoneyConfig = {
	decimal: ',',
	thousands: '.',
	prefix: 'Rp. ',
	suffix: '',
	precision: 0,
	masked: false
}



Vue.use(money, vueMoneyConfig)

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'local'

// If running inside Cypress...
if (process.env.VUE_APP_TEST === 'e2e') {
	// Ensure tests fail when Vue emits an error.
	Vue.config.errorHandler = window.Cypress.cy.onUncaughtException
}
Vue.use(VueStringFilter)
Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)
Vue.use(Vuelidate)
Vue.use(VueTour)
Vue.use(KnobControl)
Vue.use(VueMask)
Vue.use(VueEvents)
Vue.use(PrimeVue)
Vue.use(require('vue-chartist'))


Vue.use(VueGoogleMaps, {
	load: {
		key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
		libraries: 'places',
	},
	installComponents: true
})
Vue.component('apexchart', VueApexCharts)

Object.keys(rules).forEach(rule => {
	extend(rule, rules[rule]);
});

extend('after', {
	validate(value, { other }) {
		const parsed = parse(value, 'YYYY-MM-DD');
		if (!isValid(parsed)) return false;

		return isAfter(value, other);
	},
	// parse the field value.
	castValue: value => parse(value, 'YYYY-MM-DD', new Date()),
	params: [
		{
			name: 'other',
			isTarget: true,
			cast(targetValue) {
				// parse the target field value.
				return parse(targetValue, 'YYYY-MM-DD', new Date());
			}
		}
	],
	message(fieldName, placeHolder) {
		return `${fieldName} tidak boleh lebih rendah dari ${placeHolder._target_}`
	},
});

localize('id', id)

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('VueElementLoading', VueElementLoading)


// window.io = require('socket.io-client')
// window.Echo = new Echo({
//   broadcaster: 'socket.io',
//   host: process.env.VUE_APP_ECHO_URL + ':6001'
// })

Vue.directive('can', {
	bind: function (el, binding, vnode) {
		if (binding.value !== undefined) {
			if (!JSON.parse(localStorage.getItem('userPermissions'))
				.includes(binding.value)) {
				vnode.elm.style.display = "none";
			}
		} else {
			if (!JSON.parse(localStorage.getItem('userPermissions'))
				.includes(binding.expression
					.replace(/'/g, "")
					.replace(/"/g, ""))) {
				vnode.elm.style.display = "none";
			}
		}
	}
})


Vue.directive('module', {
	bind: function (el, binding, vnode) {
		console.log(binding, 'THIS BINDING')
		if (binding.value !== undefined) {
			if (!JSON.parse(localStorage.getItem('userModule'))
				.includes(binding.value)) {
				vnode.elm.style.display = "none";
			}
		} else {
			if (binding.expression !== undefined) {
				if (!JSON.parse(localStorage.getItem('userModule'))
					.includes(binding.expression
						.replace(/'/g, "")
						.replace(/"/g, ""))) {
					vnode.elm.style.display = "none";
				}
			}
		}
	}
})

Vue.directive('can-check', {
	bind: function (el, binding, vnode) {
		if (JSON.parse(localStorage.getItem('userPermissions'))
			.includes(binding.expression
				.replace(/'/g, "")
				.replace(/"/g, ""))) {
			vnode.elm.style.display = "none";
		}
	}
});
// const    Permission =  JSON.parse(localStorage.getItem('userPermissions'))


Vue.mixin({
	methods: {
		errorAlert: function (message = 'Terjadi Kesalahan') {
			this.$swal({
				title: message,
				type: 'error',
				timer: '2000',
				toast: true,
				position: 'bottom-end'
			});
		},
		successAlert: function (message = 'Success') {
			this.$swal({
				title: message,
				type: 'success',
				timer: '2000',
				toast: true,
				position: 'bottom-end'
			});
			// console.log('from mixin')
		},
		formatPrice(value) {
			let val = (value / 1).toFixed(2).replace('.', ',')
			return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
		},
		// for permission
		$can(permissionName) {
			// let arrayPermission = [];
			let Permission = JSON.parse(localStorage.getItem('userPermissions'))
			// console.log(Permission,'hi ini permission')

			// // console.log(arrayPermission, 'ini array permission')
			// if (typeof Permission !== 'undefined') {
			// 	for (let i = 0; i < Permission.length; i++) {
			// 		// const element = array[i];
			// 		var namePermission = Permission[i]
			// 		if(permissionName === namePermission){

			// 			return namePermission[permissionName];

			// 		}


			// 	}
			// }
			return Permission.includes(permissionName)

		},
		can(el, binding, vnode) {
			if (!JSON.parse(localStorage.getItem('userPermissions'))
				.includes(binding.expression
					.replace(/'/g, "")
					.replace(/"/g, ""))) {
				vnode.elm.style.display = "none";
			}
		},
		// can: (key) => Permission.includes(key),
		saveData(data, fileName) {
			var a = document.createElement("a");
			document.body.appendChild(a);
			a.style = "display: none";
			// var json = JSON.stringify(data);
			var blob = new Blob([data], { type: "octet/stream" });
			var url = window.URL.createObjectURL(blob);
			a.href = url;
			a.download = fileName;
			a.click();
			window.URL.revokeObjectURL(url);
		}
	}
})

// Uncomment this if you are having api served through other url
// Vue.prototype.$http = require('axios')
// Vue.prototype.$http.defaults.baseURL  = 'http://mock-api.coderthemes.com/'

const app = new Vue({
	el: '#app',
	router,
	store,
	async created() {


		let urlParams = new URLSearchParams(window.location.search);
		let randomUser = urlParams.get('_rnd');
		let tokenUser = urlParams.get('_tkn');


		window.axios = axios
		axios.defaults.baseURL = process.env.VUE_APP_ROOT_API

		if (tokenUser != null) {
			localStorage.setItem('access_token', tokenUser)

			axios.defaults.headers.common = {
				"Authorization": "Bearer " + localStorage.getItem("access_token"),
			};

			// get permission
			axios.get('/user/me').then((response) => {
				// console.log(response.data.result.permissions,'data permission')
				// console.log(response,'data company')
				// this.$store.state.permission = response.data.result.permissions

				let arrayPermission = [];
				let Permission = response.data.result.permissions
				// console.log(Permission,'hi ini permission')

				for (let i = 0; i < Permission.length; i++) {
					var namePermission = Permission[i].name + '_' + Permission[i].operation
					// console.log(namePermission,'name permission')
					arrayPermission.push(namePermission)
				}


				localStorage.removeItem('userPermissions')
				localStorage.setItem("userPermissions", JSON.stringify(arrayPermission));
				// this.$store.state.permission = arrayPermission


			}, () => {
				console.log("gagal")
			});

			// get data session
			axios.get('/session').then((response) => {
				console.log(response.data, 'data session')
				this.$store.state.auth.currentUser = response.data
				// console.log(this.$store.state.auth.currentUser.id_company, 'id company nya')
				localStorage.setItem('id_company', response.data.id_company)
				localStorage.setItem('session_user', response.data.id)
				// get name company
				axios.get('/company/' + response.data.id_company).then((response) => {
					// console.log(response,'data company')
					localStorage.setItem('company', response.data.name_company)
				}, () => {
					console.log("gagal")
				});

				// get logo
				axios.get('/logo/' + response.data.id).then((response) => {
					// console.log(response.data.result.profile.logo_user,'data logo')
					localStorage.setItem('logo', response.data.result.profile.logo_user)
					localStorage.setItem('name', response.data.result.profile.name_user)
					localStorage.setItem('default_branch', response.data.result.default_branch)
				}, () => {
					console.log("gagal")
				});

				axios.get('/user_branch').then((response) => {
					let arrayBranch = [];
					let Data = response.data
					// console.log(Data,'hi ini Data')

					for (let i = 0; i < Data.length; i++) {
						var branch = Data[i]
						// console.log(branch,'name permission')
						arrayBranch.push(branch)
					}
					localStorage.removeItem('list_branch')

					localStorage.setItem("list_branch", JSON.stringify(arrayBranch));

				}, () => {
					console.log("gagal")
				});

			}, () => {
				console.log("gagal")
			});

			// location.reload();
		}
		if (randomUser == null) {
			axios.defaults.headers.common = {
				"Authorization": "Bearer " + localStorage.getItem("access_token"),
			};

			// get permission
			axios.get('/user/me').then((response) => {
				// console.log(response,'data permission')
				// console.log(response.data.result.permissions,'data permission')
				// this.$store.state.permission = response.data.result.permissions

				let arrayPermission = [];
				let Permission = response.data.result.permissions
				// console.log(Permission,'hi ini permission')

				for (let i = 0; i < Permission.length; i++) {
					var namePermission = Permission[i].name + '_' + Permission[i].operation
					// console.log(namePermission,'name permission')
					arrayPermission.push(namePermission)
				}
				localStorage.removeItem('userPermissions')
				localStorage.setItem("userPermissions", JSON.stringify(arrayPermission));
				// this.$store.state.permission = arrayPermission

			}, () => {
				console.log("gagal")
			});

			// get module

			axios.get('/module').then((response) => {

				let arrayModule = [];
				let Module = response.data
				// console.log(Module,'hi ini Module')

				for (let i = 0; i < Module.length; i++) {
					var nameModule = Module[i].name_module
					// console.log(nameModule,'name Module')
					arrayModule.push(nameModule)
				}
				localStorage.removeItem('userModule')
				localStorage.setItem("userModule", JSON.stringify(arrayModule));
			}, () => {
				console.log("gagal")
			});

			// get data session
			axios.get('/session').then((response) => {
				console.log(response.data, 'data session')
				this.$store.state.auth.currentUser = response.data
				localStorage.setItem('id_company', response.data.id_company)
				localStorage.setItem('session_user', response.data.id)
				// console.log(this.$store.state.auth.currentUser.id_company, 'id company nya')
				// get name company
				axios.get('/company/' + response.data.id_company).then((response) => {
					// console.log(response,'data company')
					localStorage.setItem('company', response.data.name_company)
				}, () => {
					console.log("gagal")
				});


				// get logo
				axios.get('/logo/' + response.data.id).then((response) => {
					localStorage.setItem('logo', response.data.result.profile.logo_user)
					localStorage.setItem('name', response.data.result.profile.name_user)
					localStorage.setItem('default_branch', response.data.result.default_branch)
				}, () => {
					console.log("gagal")
				});


				axios.get('/user_branch').then((response) => {
					let arrayBranch = [];
					let Data = response.data
					// console.log(Data,'hi ini Data')

					for (let i = 0; i < Data.length; i++) {
						var branch = Data[i].branch
						// console.log(branch,'name permission')
						arrayBranch.push(branch)
					}
					localStorage.removeItem('list_branch')

					localStorage.setItem("list_branch", JSON.stringify(arrayBranch));

				}, () => {
					console.log("gagal")
				});


			}, () => {
				console.log("gagal")
			});
		}
		else {
			localStorage.random_user = randomUser
			await axios.post('/loginCode', {
				code: localStorage.getItem('random_user')
			}).then((response) => {
				// localStorage.setItem('user', response.data.username_user)
				localStorage.setItem('access_token', response.data.access_token)
				axios.defaults.headers.common = {
					"Authorization": "Bearer " + response.data.access_token,
				};

				// get permission
				axios.get('/user/me').then((response) => {
					// console.log(response.data.result.permissions,'data permission')
					// this.$store.state.permission = response.data.result.permissions

					let arrayPermission = [];
					let Permission = response.data.result.permissions
					// console.log(Permission,'hi ini permission')

					for (let i = 0; i < Permission.length; i++) {
						var namePermission = Permission[i].name + '_' + Permission[i].operation
						// console.log(namePermission,'name permission')
						arrayPermission.push(namePermission)
					}
					localStorage.removeItem('userPermissions')

					localStorage.setItem("userPermissions", JSON.stringify(arrayPermission));
					this.$store.state.permission = arrayPermission

				}, () => {
					console.log("gagal")
				});


				// get module

				axios.get('/module').then((response) => {

					let arrayModule = [];
					let Module = response.data
					// console.log(Permission,'hi ini permission')

					for (let i = 0; i < Module.length; i++) {
						var namePermission = Module[i].name_module
						arrayModule.push(namePermission)
					}
					localStorage.removeItem('userModule')
					localStorage.setItem("userModule", JSON.stringify(arrayModule));
				}, () => {
					console.log("gagal")
				});

				// get data session
				axios.get('/session').then((response) => {
					console.log(response.data, 'data session')
					this.$store.state.auth.currentUser = response.data
					localStorage.setItem('id_company', response.data.id_company)
					localStorage.setItem('session_user', response.data.id)
					// get name company
					axios.get('/company/' + response.data.id_company).then((response) => {
						// console.log(response,'data company')
						localStorage.setItem('company', response.data.name_company)
					}, () => {
						console.log("gagal")
					});

					// get logo
					axios.get('/logo/' + response.data.id).then((response) => {
						console.log(response, 'data logo')
						localStorage.setItem('logo', response.data.profile.logo_user)
					}, () => {
						console.log("gagal")
					});
				}, () => {
					console.log("gagal")
				});




			}, () => {
				console.log("gagal")
			});
		}

		axios.interceptors.request.use(function (config) {
			return config;
		}, function (error) {
			return Promise.reject(error);
		});




		axios.interceptors.response.use(function (response) {
			return response;
		}, (error) => {
			if (error.response.statusCode === 401) {
				window.location.href = process.env.VUE_APP_CI_URL;
				Swal.fire(error.response.statusText, 'Harap login terlebih dahulu!');

			}
			return Promise.reject(error);
		});

		//
		//
		// if (randomUser != null) {
		// 	localStorage.random_user = randomUser
		// 	await axios.post(process.env.VUE_APP_ROOT_API + '/loginCode', {
		// 			code: localStorage.getItem('random_user')
		// 		}).then((response) => {
		// 		localStorage.setItem('access_token', response.data.access_token)
		// 		axios.defaults.headers.common = {
		// 			"Authorization": "Bearer " + response.data.access_token,
		// 		};
		//
		// 	}, () => {
		// 		console.log("gagal")
		// 	});
		//
		// }
		//
		// axios.interceptors.request.use(async config => {
		// 	config.headers.post['header1'] = 'value';
		// 	return config;
		// })
		// axios.interceptors.response.use(function (response) {
		// 	// Any status code that lie within the range of 2xx cause this function to trigger
		// 	// Do something with response data
		// 	return response;
		// }, function (error) {
		// 	// if (error.response.status === 401)
		// 		// window.location.href = process.env.VUE_APP_CI_URL;
		// 	return Promise.reject(error);
		// });
	},
	render: (h) => h(App),
}).$mount('#app')

// If running e2e tests...
if (process.env.VUE_APP_TEST === 'e2e') {
	// Attach the app to the window, which can be useful
	// for manually setting state in Cypress commands
	// such as `cy.logIn()`.
	window.__app__ = app
}
