import axios from 'axios'

let getLocalDestinationUrl = '/api/local-destination';

export const state = {
    listLocalDestination: [],
    dataLocalDestination: {},
    errors: {}
}
export const getters = {
    
}
export const mutations = {
    setListLocalDestination: (state, payload) => {
        state.listLocalDestination = payload
    },
    setLocalDestination: (state, payload) => {
        state.dataLocalDestination = payload
    },
    setError: (state, payload) => {
        state.errors = payload
    }
}
export const actions = {
    getListLocalDestination: async (context, payload) => {
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getLocalDestinationUrl}`)
        context.commit('setListLocalDestination', data.data)
        console.log('LocalDestination', data)
        return data
    },
    saveLocalDestination: async (context, payload) => {
        let {
            data
        } = await axios.post(`${context.rootState.rootApi+getLocalDestinationUrl}`, payload)
        return data
        // context.commit('ADD_TODO',payload)
    },
    getLocalDestination: async (context, payload) => {
        try {
            let {data} = await axios.get(`${context.rootState.rootApi+getLocalDestinationUrl+'/'+payload}`)
            context.commit('setLocalDestination', data.data)
        } catch (error) {
            console.log(error)
            context.commit('setError', error.message);
        }
    },
    updateLocalDestination: async (context, {payload,id}) => {
        try {
            let {
                data
            } = await axios.put(`${context.rootState.rootApi+getLocalDestinationUrl+'/'+id}`, payload)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    deleteLocalDestination: async (context, id) => {
        try {
            let {
                data
            } = await axios.delete(`${context.rootState.rootApi+getLocalDestinationUrl+'/'+id}`)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    }
}