import axios from 'axios'

let purchaseOrder = '/purchase-order';

export const state = {
	listDocument: [],
	typeOptions: [],
	listPurchaseNumber: [],
	listItem: [],
	listPO: [],
	listPOdetail: [],
	listBuying: [],

}
export const getters = {

}
export const mutations = {
	setlistPO: (state, payload) => {
		state.listPO = payload
	},
	settypeOptions: (state, payload) => {
		state.typeOptions = payload
	},
	setlistPOdetail: (state, payload) => {
		state.listPOdetail = payload
	},
	setListBuying: (state, payload) => {
		state.listBuying = payload
	},

	setListItem: (state, payload) => {
		state.listItem = payload
	},
	setListPurchaseNumber: (state, payload) => {
		state.listPurchaseNumber = payload
	},
	getPO: (state, payload) => {
		state.listPO = payload
	},
}
function serializeQuery(params, prefix) {
	const query = Object.keys(params).map((key) => {
		const value = params[key];

		if (params.constructor === Array)
			key = `${prefix}[]`;
		else if (params.constructor === Object)
			key = (prefix ? `${prefix}[${key}]` : key);

		if (typeof value === 'object')
			return serializeQuery(value, key);
		else
			return `${key}=${encodeURIComponent(value)}`;
	});

	return [].concat.apply([], query).join('&');
}
export const actions = {
	getlistPO: async (context, id) => {

		let {
			data
		} = await axios.get(`${context.rootState.rootApi + purchaseOrder + '/edit/' + id}`)
		context.commit('getPO', data)
		return data
	},
	getlistBalancePO: async (context, id) => {

		let {
			data
		} = await axios.get(`${context.rootState.rootApi + purchaseOrder + '/get-item-balance/' + id}`)
		context.commit('getPO', data)
		return data
	},
	getlistBalancePOMulti: async (context, idMulti) => {
		let result = serializeQuery(idMulti, 'id');

		let {
			data
		} = await axios.get(`${context.rootState.rootApi + purchaseOrder + '/get-item-balance-multi?' + result}`)
		context.commit('getPO', data)
		return data
	},
	getlistPoDetails: async (context, payload) => {
		let {
			data
		} = await axios.post(`${context.rootState.rootApi + purchaseOrder + '/detail'}`, payload)
		context.commit('getPOdatail', data)
		return data
	},
	savePurchaseOrder: async (context, payload) => {
		try {
			let {
				data
			} = await axios.post(`${context.rootState.rootApi + purchaseOrder + '/store'}`, payload)
			return data

		} catch (error) {
			context.commit('setError', error)
		}
		// context.commit('ADD_TODO',payload)
	},
	getPurchaseOrderNumber: async (context, payload) => {
		let {
			data
		} = await axios.post(`${context.rootState.rootApi + purchaseOrder + '/getPurchaseOrderNumber'}`, payload)
		context.commit('setListPurchaseNumber', data)
		return data
	},
	getListItem: async (context, payload) => {
		let {
			data
		} = await axios.post(`${context.rootState.rootApi + purchaseOrder + '/getItem'}`, payload)
		// console.log(data.purchase_order_item,'dataItem');
		context.commit('setListItem', data)
		return data
	},
	// deleteJobOrderDistribution: async (context, id) => {
	//     try {
	//         let {
	//             data
	//         } = await axios.get(`${context.rootState.rootApi + distribution + '/delete/' + id}`)
	//         return data
	//     } catch (error) {
	//         console.log(error)
	//         context.commit('setError', error)
	//     }
	// },
	updatePurchaseOrder: async (context, payload) => {
		try {
			let {
				data
			} = await axios.post(`${context.rootState.rootApi + purchaseOrder + '/update'}`, payload)
			return data
		} catch (error) {
			context.commit('setError', error)
		}
		// context.commit('ADD_TODO',payload)
	},

	deletePurchaseOrder: async (context, id) => {
		try {
			let {
				data
			} = await axios.get(`${context.rootState.rootApi + purchaseOrder + '/delete/' + id}`)
			return data
		} catch (error) {
			context.commit('setError', error)
		}
	},

	hardDeletePurchaseOrder: async (context, id) => {
		try {
			let {
				data
			} = await axios.get(`${context.rootState.rootApi + purchaseOrder + '/hard-delete/' + id}`)
			return data
		} catch (error) {
			context.commit('setError', error)
		}
	},

	submitAOL: async (context, id) => {
		try {
			let {
				data
			} = await axios.get(`${context.rootState.rootApi + purchaseOrder + '/submit-aol/' + id}`)
			return data
		} catch (error) {
			console.log(error)
			context.commit('setError', error)
		}
	},
	getListBuying: async (context, payload) => {
		let {
			data
		} = await axios.get(`${context.rootState.rootApi + purchaseOrder}/get-applied-transaction?id_purchase_order=${payload.idJoborder}&search=${payload.search}&page=${payload.page}&per_page=${payload.per_page}`)
		context.commit('setListBuying', data)
		return data
	},

	getListPotoRi: async (context, payload) => {
		let {
			data
		} = await axios.post(`${context.rootState.rootApi + purchaseOrder + '/get-applied-ri-to-ri'}`, payload)
		context.commit('getPOdatail', data)
		return data
	},
	// saveDistributionBackupTruck: async (context, payload) => {
	//     let {
	//         data
	//     } = await axios.post(`${context.rootState.rootApi + distribution + '/backup-truck'}`, payload)
	//     return data
	//     // context.commit('ADD_TODO',payload)
	// },


}
