import axios from 'axios'

let getAutonumberUrl = '/autonumber';

export const state = {
    listAutonumber: [],
    listAutonumberReceive: [],
    listAutonumberPP: [],
    listAutonumberPPTax: [],
    listAutonumberJV: [],
    dataPort: {},
    errors: {}
}
export const getters = {

}
export const mutations = {
    setListAutonumber: (state, payload) => {
        state.listAutonumber = payload
    },
    setListAutonumberReceive: (state, payload) => {
        state.listAutonumberReceive = payload
    },
    setListAutonumberPP: (state, payload) => {
        state.listAutonumberPP = payload
    },
    setListAutonumberPPTax: (state, payload) => {
        state.listAutonumberPPTax = payload
    },
    setPort: (state, payload) => {
        state.dataPort = payload
    },
    setListAutonumberJV: (state, payload) => {
        state.listAutonumberJV = payload
    },
    setError: (state, payload) => {
        state.errors = payload
    }
}
export const actions = {
    getListAutonumber: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getAutonumberUrl}/getAutonumber/${payload}`)
        context.commit('setListAutonumber', data)
        return data
    },
    getListAutonumberReceive: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getAutonumberUrl}/getAutonumber/${payload}`)
        context.commit('setListAutonumberReceive', data)
        return data
    },
    getListAutonumberPP: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getAutonumberUrl}/getAutonumber/${payload}`)
        context.commit('setListAutonumberPP', data)
        return data
    },
    getListAutonumberPPTax: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getAutonumberUrl}/getAutonumber/${payload}`)
        context.commit('setListAutonumberPPTax', data)
        return data
    },
    getListAutonumberFlexmod: async (context, payload) => {
        // let config = {
        //     headers: {
        //         'company' : 2
        //     }
        // }
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getAutonumberUrl}/getAutonumberFlexmod/${payload.id_flexmod}/${payload.id_module}`)
        context.commit('setListAutonumber', data)
        return data
    },
    savePort: async (context, payload) => {
        let {
            data
        } = await axios.post(`${context.rootState.rootApi+getAutonumberUrl}`, payload)
        return data
        // context.commit('ADD_TODO',payload)
    },
    getPort: async (context, payload) => {
        try {
            let {data} = await axios.get(`${context.rootState.rootApi+getAutonumberUrl+'/'+payload}`)
            context.commit('setPort', data)
						console.log('Port', data)
        } catch (error) {
            console.log(error)
            context.commit('setError', error.message);
        }
    },
    updatePort: async (context, {payload,id}) => {
        try {
            let {
                data
            } = await axios.put(`${context.rootState.rootApi+getAutonumberUrl+'/'+id}`, payload)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    deletePort: async (context, id) => {
        try {
            let {
                data
            } = await axios.delete(`${context.rootState.rootApi+getAutonumberUrl+'/'+id}`)
            return data
        } catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
    getListAutonumberJV: async (context, payload) => {
        let {
            data
        } = await axios.get(`${context.rootState.rootApi+getAutonumberUrl}/getAutonumber/${payload}`)
        context.commit('setListAutonumberJV', data)
        return data
    },
}
